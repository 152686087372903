import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Badge, Button, Card, CardBody, Col, Container, Form, Input, Label, List, Modal, ModalBody, ModalHeader, ModalFooter, Row, Table, UncontrolledCollapse } from 'reactstrap';
import * as orderStatuses from '../../constants/orderStatuses';
import { workspaceUrl } from '../../constants/onehubConfig';
import { WORDPRESS_ORDER, VRX_PHOTOS_ORDER, orderTypeNames } from '../../constants/orderTypes';

const OrdersComponent = ({ fetchCustomerOrders, filterCustomerOrders, ordersArray, setCustomerOrderStatus, sortCustomerOrders }) => {

  const [orderModalOpen, setOrderModalOpen] = useState(false);
  const toggleOrderModal = () => setOrderModalOpen(!orderModalOpen);
  const [order, setOrder] = useState({});
  const [orderDate, setOrderDate] = useState('');
  const [asc, setAsc] = useState(false);
  const defaultStartDate = new Date();
  defaultStartDate.setMonth(defaultStartDate.getMonth() - 3);

  useEffect(() => {
    fetchCustomerOrders();
  }, [fetchCustomerOrders]);

  return (
    <Container>
      <Row>
        <Col>
          <div className="d-flex justify-content-between align-items-center">
            <h1>Customer Orders</h1>
            <div><Button id="filters" className="" outline>Filters</Button></div>
          </div>
          <UncontrolledCollapse toggler="#filters" className="my-3">
            <Card>
              <CardBody>
                <Form onChange={filterCustomerOrders}>
                  <Row>
                    <Col sm="6">
                      <Label for="startDate">Start Date</Label>
                      <Input type="date" name="startDate" id="startDate" defaultValue={defaultStartDate.toISOString().substring(0, 10)}/>
                    </Col>
                    <Col sm="6">
                      <Label for="endDate">End Date</Label>
                      <Input type="date" name="endDate" id="endDate" defaultValue={(new Date()).toISOString().substring(0, 10)}/>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                   <Col sm="6">
                      <Label for="customerEmail">Customer Email</Label>
                      <Input id="customerEmail" name="customerEmail"></Input>
                    </Col>
                    <Col sm="6">
                      <Label for="address">Address</Label>
                      <Input id="address" name="address"></Input>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col sm="6">
                      <Label for="statusSelect">Status</Label>
                      <Input id="statusSelect" name="status" type="select">
                        <option value="">-- select status --</option>
                        {Object.entries(orderStatuses).map(entry => 
                          entry[0] !== 'statusColors' ? <option value={entry[1]} key={`productFilter-${entry[0]}`}>{entry[1]}</option> : ''
                        )}
                      </Input>
                    </Col>
                    <Col sm="6">
                      <Label for="originSelect">Order Origin</Label>
                      <Input id="originSelect" name="origin" type="select">
                        <option value="">-- select origin --</option>
                        <option value={VRX_PHOTOS_ORDER}>{orderTypeNames.VRX_PHOTOS_ORDER}</option>
                        <option value={WORDPRESS_ORDER}>{orderTypeNames.WORDPRESS_ORDER}</option>
                        {/* {Object.entries(orderStatuses).map(entry => 
                          entry[0] !== 'statusColors' ? <option value={entry[1]} key={`productFilter-${entry[0]}`}>{entry[1]}</option> : ''
                        )} */}
                      </Input>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </UncontrolledCollapse>
        </Col>
      </Row>
      <Table hover responsive>
        <thead>
          <tr onClick={() => setAsc(!asc)}>
            <th className="sort" onClick={e => sortCustomerOrders('customerEmail', asc)}>Email</th>
            <th className="sort" onClick={e => sortCustomerOrders('addressString', asc)}>Address</th>
            <th className="sort" onClick={e => sortCustomerOrders('created', asc)}>Date</th>
            <th className="">Products</th>
            <th className="sort" onClick={e => sortCustomerOrders('quantity', asc)}>Quantity</th>
            <th className="sort" onClick={e => sortCustomerOrders('status', asc)}>Status</th>
          </tr>
        </thead>
        <tbody>
          {ordersArray.map((order, index) => {
            const date = new Date(order.created);
            const created = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
            return <tr onClick={() => {

              setOrder(order);
              setOrderDate(created);
              toggleOrderModal();

            }} key={`order-${index}`} className="cursor-pointer" order-id={order.id}>
              <td>{order.customerEmail}</td>
              <td>{order.addressString}</td>
              <td>{created}</td>
              <td>
                <List type="unstyled">
                  {Object.entries(order.products || {}).map(entry => <li key={`order-${index}_product-${entry[0]}`}>
                    {entry[1].name}
                  </li>)}
                </List>
              </td>
              <td>{order.quantity}</td>
              <td><Badge color={orderStatuses.statusColors[order.status]}>{order.status}</Badge></td>
            </tr>
          })}
        </tbody>
      </Table>

      <Modal isOpen={orderModalOpen} toggle={toggleOrderModal}>
        <Form onSubmit={e => {
          toggleOrderModal()
          setCustomerOrderStatus(e, order)
        }}>
          <ModalHeader toggle={toggleOrderModal}>Customer Order</ModalHeader>
          <ModalBody>
            <Row>
              
              {/* <Col md="4" tag="dt">Products</Col>
              <Col md="8" tag="dd">
                <List type="unstyled" className="mb-0">
                  {order.products ? Object.entries(order.products).map(entry => <li key={`order-modal_product-${entry[0]}`}>
                    {typeof entry[1] === 'string' ? entry[1] : entry[1].name}
                  </li>) : null}
                </List>
              </Col> */}

              <Col md="4" tag="dt">Date</Col>
              <Col md="8" tag="dd">{orderDate}</Col>

              <Col md="4" tag="dt">Quantity</Col>
              <Col md="8" tag="dd">{order.quantity}</Col>

              <Col md="4" tag="dt">OneHub Folder</Col>
              <Col md="8" tag="dd"><a href={`${workspaceUrl}/folders/${order.oneHubFolder}`} target="_blank" rel="noreferrer">{order.oneHubFolder}</a></Col>

              {order.oneHubFile ? <Col md="4" tag="dt">Download Files</Col> : null}
              {order.oneHubFile ? <Col md="8" tag="dd"><a href={`${workspaceUrl}/files/${order.oneHubFile}/download`} target="_blank" rel="noreferrer">{order.oneHubFile}</a></Col> : null}

              {order.processedZipURL ? <Col md="4" tag="dt">Processed URL</Col> : ''}
              {order.processedZipURL ? <Col md="8" tag="dd"><a href={order.processedZipURL} target="_blank" rel="noreferrer"><small>{order.id}_processed.zip</small></a></Col> : ''}

              <Col md="4" tag="dt">Name</Col>
              <Col md="8" tag="dd">{order.customerName || 'not set'}</Col>

              <Col md="4" tag="dt">Email</Col>
              <Col md="8" tag="dd">{order.customerEmail || 'not set'}</Col>

              {order.notificationEmailAddresses?.length ? <Col md="4" tag="dt">Other Emails</Col> : ''}
              {order.notificationEmailAddresses?.length ? <Col md="8" tag="dd">
                {order.notificationEmailAddresses.join(', ')}
              </Col> : ''}

              {order.notes ? <Col md="4" tag="dt">Notes</Col> : ''}
              {order.notes ? <Col md="8" tag="dd">{order.notes}</Col> : ''}

              {order.address?.street1 ? <Col md="4" tag="dt">Address</Col> : ''}
              {order.address?.street1 ? <Col md="8" tag="dd">
                <div>{order.address.street1}</div>
                {order.address.street2 ? <div>{order.address.street2}</div> : ''}
                <div>{order.address.city}, {order.address.state} {order.address.zip}</div>
              </Col> : ''}
              <Col md="4" tag="dt">Photos</Col>
              {order.files?.length ? <Col md="8" tag="dd">
                {Object.entries(order.products).map((entry, index) => 
                  <div key={`product-list-${index}`}>
                    <strong>{entry[1].name}</strong>
                    <table>
                      <tbody>
                        {order.files.map((obj, imgNum) =>
                          obj.productId === entry[0] || decodeURI(obj.downloadURL).replace('%26', '&').includes(entry[1].name) ? <tr key={`finalize-image-list-${imgNum}`}>
                            <td>
                              <div className="d-inline-block finalize-images__image me-3 mb-2 rounded shadow-sm"
                                style={{ backgroundImage: `url(${obj.downloadURL})` }}></div>
                            </td>
                            <td className="align-top img-notes"><strong>{obj.name}</strong><br />{obj.note}</td>
                          </tr> : null
                        )}
                      </tbody>
                    </table>
                  </div>
                )  
              }
              </Col> : <Col md="8" tag="dd">none</Col>}

              {order.revisions?.length ? <Col md="4" tag="dt">{order.revisions.length > 1 ? 'Revisions' : 'Revision'}</Col> : ''}
              {order.revisions?.length ? <Col md="8" tag="dd">{order.revisions.map((rev, index) => <p key={`revision-${index}`}>{rev}</p>)}</Col> : ''}

              <Col md="4" tag="dt">
                <Label for="status">Status</Label>
              </Col>
              <Col md="8" tag="dd">
                <Input id="status" name="status" type="select" defaultValue={order.status}>
                  {Object.entries(orderStatuses).map(entry =>
                    entry[0] === 'statusColors' ? '' : <option key={`order-status-${entry[0]}`} value={entry[1]}>{entry[1]}</option>
                  )}
                </Input>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleOrderModal}>Close</Button>
            <Button className="btn-vrx">Save</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </Container>
  );
};

OrdersComponent.propTypes = {
  fetchCustomerOrders: PropTypes.func.isRequired,
  filterCustomerOrders: PropTypes.func.isRequired,
  ordersArray: PropTypes.array.isRequired,
  setCustomerOrderStatus: PropTypes.func.isRequired,
  sortCustomerOrders: PropTypes.func.isRequired
};

export default OrdersComponent;