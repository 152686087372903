import { connect } from 'react-redux';
import OrdersComponent from './OrdersComponent';
import { fetchOrdersIfNeeded, setOrder } from '../../actions';
import { orders } from '../../constants/routes';

const mapStateToProps = state => ({
  ordersArray: state.orders.items
});

const mapDispatchToProps = (dispatch) => ({
  fetchOrders: () => dispatch(fetchOrdersIfNeeded()),
  onOrderClick: (navigate, order) => {
    dispatch(setOrder(order));
    navigate(`${orders}/${order.id}`);
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrdersComponent);