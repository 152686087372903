import { connect } from 'react-redux';
import CustomerOrdersComponent from './CustomerOrdersComponent';
import { fetchCustomerOrdersIfNeeded, filterCustomerOrders, getDownloadURLFromPath, sortCustomerOrders, setCustomerOrderStatus } from '../../actions';
import { formIsValid } from '../../utils';

const mapStateToProps = state => ({
  ordersArray: state.customerOrders.filteredItems
});

const mapDispatchToProps = dispatch => ({
  fetchCustomerOrders: () => dispatch(fetchCustomerOrdersIfNeeded()),
  filterCustomerOrders: e => dispatch(filterCustomerOrders(e)),
  getDownloadURLFromPath,
  setCustomerOrderStatus: (e, order) => {
    if(!formIsValid(e))
      return;
    dispatch(setCustomerOrderStatus(order, e.target.status.value));
  },
  sortCustomerOrders: (prop, asc) => dispatch(sortCustomerOrders(prop, asc))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerOrdersComponent);