import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';

const Blocker = ({ message }) => (
  <div id="blocker" className="blocker">
    <div className="text-center">
      <Spinner color="light" />
      {message ? <h3 className="text-light mt-3 mx-4">{message}</h3> : ''}
    </div>
  </div>
);

Blocker.protoTypes = {
  message: PropTypes.string
};

export default Blocker;