export const forgotPassword = '/forgot-password';
export const getStarted = '/get-started';
export const home = '/';
export const loading = '/loading';
export const login = '/login';
export const logout = '/logout';

//orders
export const customerOrders = '/customer-orders';
export const kwOrder = '/kw/:id';
export const newKwOrder = 'https://marketplace.kw.com/apps/317224/vrx-media-virtual-staging';
export const completeOrder = '/complete/:id';
export const orders = '/orders';
export const order = `${orders}/:id`;
export const newOrderId = 'new-order';
export const newOrder = `${orders}/${newOrderId}`;
export const resendOrder = '/resend/:id';
export const vrxOrder = '/vrx-order/:id';

export const products = '/products';
export const profile = '/profile';
export const register = '/register';
export const registerListReports = '/list-reports-registration';
export const registerRedfin = '/redfin-registration';
export const verify = '/verify';
export const cart = '/cart';

export const kwMarketPlace = 'https://marketplace.kw.com';

const loggedInRoutes = [
  logout,
  verify
];

const verifiedRoutes = [
  getStarted,
  orders,
  products,
  profile,
  cart
];

const adminRoutes = [
  customerOrders
];

export const adminAccessRequired = route => adminRoutes.includes(`/${route.split('/')[1]}`);
export const loginRequired = route => loggedInRoutes.includes(`/${route.split('/')[1]}`);
export const verificationRequired = route => verifiedRoutes.includes(`/${route.split('/')[1]}`);