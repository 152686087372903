import { REQUEST_CUSTOMER_ORDERS, RESET_CUSTOMER_ORDER, RESET_CUSTOMER_ORDERS, SET_CUSTOMER_ORDER, SET_CUSTOMER_ORDERS, SET_FILTERED_CUSTOMER_ORDERS, SET_USER } from '../actions/types';
import { newOrder } from './ordersReducer';

const initialState = {
  fetched: false,
  fetching: false,
  items: [],
  filteredItems: [],
  order: newOrder
};

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_CUSTOMER_ORDERS: 
      return Object.assign({}, state, { fetching: true });
    case RESET_CUSTOMER_ORDERS: 
      return Object.assign({}, initialState);
    case SET_CUSTOMER_ORDERS:
      return Object.assign({}, state, {
        fetched: true,
        fetching: false,
        items: action.customerOrders,
      });
    case SET_FILTERED_CUSTOMER_ORDERS:
      return Object.assign({}, state, {
        filteredItems: action.filteredCustomerOrders,
      });
    case RESET_CUSTOMER_ORDER:
      return Object.assign({}, state, { order: newOrder });
    case SET_CUSTOMER_ORDER:
      return Object.assign({}, state, { order: action.order });
    case SET_USER:
      return action.user ? state : Object.assign({}, initialState);
    default:
      return state;
  }
};

export default ordersReducer;