import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Alert, Badge, Button, Card, CardBody, CardImg, CardSubtitle, CardTitle, Col, Form, FormGroup, Input, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { LIST_REPORTS_CUSTOMER, REDFIN_CUSTOMER } from '../../constants/roles';
import { cart } from '../../constants/routes';

const Products = ({ fetchProducts, onOrderFormSubmit, products, role }) => {

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts, role]);

  // order modal
  const navigate = useNavigate();
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [modalImg, setModalImg] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [product, setProduct] = useState({});
  const [lastProductAdded, setLastProductAdded] = useState();
  const [quantity, setQuantity] = useState(1);
  const toggleOrderModal = () => {
    setQuantity(1);
    setShowOrderModal(!showOrderModal);
  }

  return <Row className="products">
    <Col>
      {role === LIST_REPORTS_CUSTOMER ? 
        <img src={`${process.env.PUBLIC_URL}/img/ListReportsLogo_2.png`} className="list-report-logo mb-4" alt="List Reports" title="List Reports" /> : 
      ''}
      {role === REDFIN_CUSTOMER ? 
        <img src={`${process.env.PUBLIC_URL}/img/redfin_full_logo_1x.png`} className="redfin-logo mb-2" alt="Redfin" title="Redfin" srcSet={`${process.env.PUBLIC_URL}/img/redfin_full_logo_1x.png 1x, ${process.env.PUBLIC_URL}/img/redfin_full_logo_2x.png 2x`} /> : 
      ''}
      {role !== LIST_REPORTS_CUSTOMER && role !== REDFIN_CUSTOMER ? <h1>Products</h1> : '' }
      {lastProductAdded ? <Row>
        <Col className="mb">
          <Alert color="light" className="added-to-cart-msg clearfix">
            <span>&ldquo;{lastProductAdded.name}&rdquo; was added to your cart.</span>
            <Link to={cart} className="btn btn-sm btn-outline-secondary float-end">View cart &rarr;</Link>
          </Alert>
        </Col>
      </Row> : ''}
      <Row>
        {products.map((product, index) => {
          product.images[0].src = `${process.env.PUBLIC_URL}/img/products/${product.id}.jpg`;
          return <Col sm="6" lg="4" className="mb-4" key={product.id}>
            <Card className="shadow-sm">
              <CardImg top src={product.images[0].src} alt={product.images[0].name} title={product.name}/>
              <CardBody>
                <CardTitle tag="h6">{product.name} {product.on_sale ? <sup><Badge color="success">Sale!</Badge></sup> : ''}</CardTitle>
                {role !== REDFIN_CUSTOMER ? <div>
                  {product.on_sale ? 
                    <CardSubtitle className="mb-2 text-muted">
                      <small className="line-through">${product.regular_price}</small> ${product.price}
                    </CardSubtitle> : 
                    <CardSubtitle className="mb-2 text-muted">${parseFloat(product.price).toFixed(2) || '0.00'}</CardSubtitle> }
                    </div> : '' }
                  <Button className="btn-vrx" onClick={_e => {
                    setModalTitle(product.name);
                    setModalImg(product.images[0]);
                    setProduct(product);
                    toggleOrderModal();
                  }}>Order</Button>
              </CardBody>              
            </Card>            
          </Col>
        })}
      </Row>
    </Col>
    <Modal isOpen={showOrderModal} toggle={toggleOrderModal}>
      <Form onSubmit={e => onOrderFormSubmit(e, product, toggleOrderModal, navigate, setLastProductAdded)}>
        <ModalHeader toggle={toggleOrderModal}>{modalTitle}</ModalHeader>
        <ModalBody>
          <img src={modalImg.src} className="img-fluid" alt={modalImg.name} title={modalTitle} />
          <FormGroup>
            <Label for="quantity" className="mt-4">How many photos?</Label>
            <InputGroup className="mw-9rem">
              <Button className="btn-vrx" onClick={_ => quantity > 1 ? setQuantity(parseInt(quantity) - 1) : setQuantity(1) }> - </Button>
              <Input type="number" className="no-arrows text-center" name="quantity" id="quantity" inputMode="numeric" placeholder="1" min="1" max="100" value={quantity} onChange={e => setQuantity(e.target.value)}/>
              <Button className="btn-vrx" onClick={_ => quantity < 100 ? setQuantity(parseInt(quantity) + 1) : setQuantity(100) }> + </Button>
            </InputGroup>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleOrderModal}>Cancel</Button>
          <Button className="btn-vrx">Add to Cart</Button>
        </ModalFooter>
      </Form>
    </Modal>
  </Row>;
}

Products.propTypes = {
  fetchProducts: PropTypes.func.isRequired,
  onOrderFormSubmit: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default Products;