import { blockUi, showAlert } from './';
import { REQUEST_PRODUCTS, SET_PRODUCTS } from './types';
import { auth, functionsURL } from '../constants/firebase';
import { sortArrayOfObjects } from '../utils';
import { REDFIN_CUSTOMER } from '../constants/roles';
import { REDFIN_ALLOWED_PRODUCTS } from '../constants/redfinAllowedProducts';

export const fetchProductsIfNeeded = () => async (dispatch, getState) => {
  const state = getState();
  if(state.products.fetching) return;
  if(state.products.fetched) return;
  if(!state.user?.claims) return;
  dispatch(blockUi(true));
  dispatch(requestProducts());
  try {
    const token = await auth.currentUser.getIdToken();
    const options = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const res = await fetch(`${functionsURL}/vrx/products`, options);
    let products = sortArrayOfObjects(await res.json(), 'menu_order');
    if(state.user?.claims?.role === REDFIN_CUSTOMER)
      products = products.filter(product => REDFIN_ALLOWED_PRODUCTS.indexOf(product.id) > -1);
    dispatch(setProducts(products));
  } catch(e) {
    dispatch(showAlert('danger', 'Could not retrieve products'));
  }
  dispatch(blockUi(false));
};

const requestProducts = () => ({
  type: REQUEST_PRODUCTS
});

const setProducts = products => ({ 
  type: SET_PRODUCTS, 
  products
});