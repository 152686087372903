export const CANCELED = 'canceled';
export const COMPLETE = 'complete';
export const NEW = 'new';
export const PENDING = 'pending';
export const PROCESSING = 'processing';
export const REVISION = 'revision';

export const statusColors = {
  complete: 'secondary',
  canceled: 'danger',
  new: 'success',
  pending: 'info',
  processing: 'primary',
  revision: 'warning'
};