import React from "react";
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import states from '../constants/states.json';

const AddressComponent = ({ address }) => {
  return <div>
    <FormGroup>
      <Label for="street1" className="required">Street</Label>
      <Input type="text" name="street1" id="street1" placeholder="12345 Street" required defaultValue={address?.street1}/>
      <Input className="mt-2" type="text" name="street2" id="street2" placeholder="apt #, suite, etc." defaultValue={address?.street2}/>
    </FormGroup>
    <Row>
      <Col md="6">
        <FormGroup>
          <Label for="city" className="required">City</Label>
          <Input type="text" name="city" id="city" placeholder="City" required defaultValue={address?.city}/>
        </FormGroup>
      </Col>
      <Col md="3">
        <FormGroup>
          <Label for="state" className="required">State</Label>
          <Input type="select" name="state" id="state" required defaultValue={address?.state}>
            <option value="">--</option>
            {states.map((state, i) => <option value={state.code} key={`state-${i}`} >{state.code}</option>)}
          </Input>
        </FormGroup>
      </Col>
      <Col md="3">
        <FormGroup>
          <Label for="zip" className="required">Zip</Label>
          <Input type="text" name="zip" id="zip" placeholder="12345" required defaultValue={address?.zip}/>
        </FormGroup>
      </Col>
    </Row>
  </div>
};

export default AddressComponent;
