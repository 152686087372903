import React from 'react';
// import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
// import { getStarted } from '../../constants/routes';

const Home = () => (
  <div className="rounded px-3 px-sm-4 py-3 py-sm-5 mt-5">
    <Row>
      <Col className="text-center">
        <img src={`${process.env.PUBLIC_URL}/img/vrx_staging_logo.png`}
          srcSet={`${process.env.PUBLIC_URL}/img/vrx_staging_logo.png 1x, ${process.env.PUBLIC_URL}/img/vrx_staging_logo@2x.png 2x`}
          className="img-fluid" alt="logo" title="VRX Staging" />
        <br/>
        {/* <Link to={getStarted} className="btn btn-vrx btn-lg mt-5">Get Started</Link> */}
      </Col>
    </Row>
  </div>
);

export default Home;