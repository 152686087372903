import { 
  addNotificationEmailAddress,
  blockUi,
  cancelOrder,
  deleteFiles,
  deleteImagesForProduct,
  fetchOrderIfNeeded,
  finalizeOrder,
  orderFilesSelected,
  removeNotificationEmail,
  requestRevision,
  saveOrderAddress,
  showAlert,
  uploadFiles
} from '../../actions';
import EditOrder from './EditOrderComponent';
import { COMPLETE } from '../../constants/orderStatuses';
import { connect } from 'react-redux';
import { orders } from '../../constants/routes';
import { formIsValid } from '../../utils';

const notificationEmailFormSubmit = (e, dispatch) => {
  if (!formIsValid(e)) return;
  dispatch(addNotificationEmailAddress(e.target.email.value));
}

const fetchOrder = async (dispatch, id) => {
  try{
    await dispatch(fetchOrderIfNeeded(id));
  } catch (e) {
    dispatch(showAlert('danger', e.message));
  }
};

const onAddressSubmit = async (e, dispatch, navigate) => {
  
  e.preventDefault();
  e.stopPropagation();

  const form = e.target;

  if (!form.checkValidity()) {
    e.target.classList.add('was-validated');
    return;
  }

  dispatch(blockUi(true));

  try {
    const orderId = await dispatch(saveOrderAddress(form));
    dispatch(showAlert('success', 'The address has been saved! Your next step is to upload your photos.'));
    navigate(`${orders}/${orderId}`);
  } catch (e) {
    dispatch(showAlert('danger', e.message));
  }

  dispatch(blockUi(false));

}

const showDownload = order => {
  if(order.status !== COMPLETE) return false;

  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
  const completedDate = new Date(order.completedDate);
  return order.processedZipURL !== undefined &&
    order.processedZipURL !== null &&
    completedDate > sevenDaysAgo
}

const mapStateToProps = state => ({
  files: state.orders.order.files,
  order: state.orders.order,
  notificationEmailAddresses: state.orders.order.notificationEmailAddresses || [],
  role: state.user.claims?.role || '',
  showDownload: showDownload(state.orders.order)
});

const mapDispatchToProps = dispatch => ({
  notificationEmailFormSubmit: e => notificationEmailFormSubmit(e, dispatch),
  fetchOrder: orderId => fetchOrder(dispatch, orderId),
  noPaymentGatewayAlert: () => dispatch(showAlert('danger', 'No payment gateway. Please, use https://vrxstaging.com to place your order')),
  onAddressSubmit: (e, navigate) => onAddressSubmit(e, dispatch, navigate),
  onCancelOrderClick: (navigate, toggleCancelModal) => dispatch(cancelOrder(navigate, toggleCancelModal)),
  onDeleteFilesBtnClick: e => dispatch(deleteFiles(e)),
  onFilesChange: (e, productId) => dispatch(orderFilesSelected(e, productId)),
  onFilesSubmit: (e, setProgress) => dispatch(uploadFiles(e, setProgress)),
  finalizeOrder: navigate => dispatch(finalizeOrder(navigate)),
  removeNotificationEmail: index => dispatch(removeNotificationEmail(index)),
  requestRevision: (e, navigate, toggleRevisionModal) => { 
    e.preventDefault();
    dispatch(requestRevision(e.target.revision.value, navigate, toggleRevisionModal));
  },
  resetFilesForProduct: productId => dispatch(deleteImagesForProduct(productId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditOrder);