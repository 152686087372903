import { connect } from 'react-redux';
import ImageUploadComponent from './ImageUploadComponent';
import { loadPreviewImage } from '../actions';

const mapStateToProps = (state, props) => ({
  dataURL: state.orders.order.files[props.imgNum].dataURL || '',
  fileName: state.orders.order.files[props.imgNum].name
});

const mapDispatchToProps = (dispatch, props) => ({
  onMount: () => dispatch(loadPreviewImage(props.imgNum))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImageUploadComponent)