import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const ResendOrderComponent = ({ resendOrder }) => {
  
  useEffect(() => {
    resendOrder();
  })
  
  return (
    <div></div>
  );
};

ResendOrderComponent.propTypes = {
  resendOrder: PropTypes.func.isRequired
};

export default ResendOrderComponent;