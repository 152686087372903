import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const CompleteOrderComponent = ({ completeOrder }) => {
  
  useEffect(() => {
    completeOrder();
  })
  
  return (
    <div></div>
  );
};

CompleteOrderComponent.propTypes = {
  completeOrder: PropTypes.func.isRequired
};

export default CompleteOrderComponent;