import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Input, Spinner } from 'reactstrap';

const ImageUpload = ({ dataURL, fileName, imgNum, onMount}) => {
  useEffect(onMount, [onMount]);
  return <Col lg="3" sm="6" xs="12" className="image-upload mx-2">
    {dataURL ? <div className={`image-upload__container rounded text-end ${!dataURL ? 'd-none' : ''}`} style={{ backgroundImage:`url(${dataURL})` }}>
      </div>
      : <div className={`image-upload__spinner ${dataURL ? 'd-none' : 'd-flex rounded justify-content-center align-items-center'}`}>
        <Spinner color="light" />
      </div>
    }
    <p>{fileName}</p>
    <Input bsSize="sm" type="textarea" name={`note_${imgNum}`}/>
  </Col>;
};

ImageUpload.propTypes = {
  enableCloseBtn: PropTypes.bool,
  dataURL: PropTypes.string,
  fileName: PropTypes.string.isRequired,
  onMount: PropTypes.func.isRequired
};

export default ImageUpload;