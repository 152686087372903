import { REQUEST_ORDERS, RESET_ORDER, RESET_ORDERS, SET_ORDER, SET_ORDERS, SET_USER } from '../actions/types';
import { NEW } from '../constants/orderStatuses';

export const newOrder = {
  address: {
    street1: '',
    street2: '',
    city: '',
    state: '',
    zip: ''
  },
  addressString: '',
  customerEmail: '',
  customerId: '',
  customerName: '',
  files: [],
  id: '',
  notes: '',
  notificationEmailAddresses: [],
  paymentIntent: '',
  paymentIntentStatus: '',
  price: '',
  productId: '',
  products: {},
  quantity: 0,
  revisions: [],
  status: NEW,
  type: '',
  zipURL: ''
};

const initialState = {
  fetched: false,
  fetching: false,
  items: [],
  order: newOrder
};

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ORDERS: 
      return Object.assign({}, state, { fetching: true });
    case RESET_ORDERS: 
      return Object.assign({}, initialState);
    case SET_ORDERS:
      return Object.assign({}, state, {
        fetched: true,
        fetching: false,
        items: action.orders
      });
    case RESET_ORDER:
      return Object.assign({}, state, { order: newOrder });
    case SET_ORDER:
      return Object.assign({}, state, { order: action.order });
    case SET_USER:
      return action.user ? state : Object.assign({}, initialState);
    default:
      return state;
  }
};

export default ordersReducer;