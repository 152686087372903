import { getCartRef } from '../constants/firebase';
import { deleteDoc, getDoc, setDoc } from 'firebase/firestore';
import { REQUEST_CART, RESET_CART, SET_CART_ITEMS } from './types';
import { blockUi } from './';

export const addToCart = (productId, quantity) => async (dispatch, getState) => {
  if(quantity <= 0) return;
  dispatch(blockUi(true));
  const state = getState();
  let dupeItem = false;
  let items = state.cart.items.map(item => {
    if(item.productId === productId) {
      dupeItem = true;
      item.quantity += quantity;
    }
    return item;
  });
  items = dupeItem ? items : items.concat({productId, quantity});
  await setDoc(getCartRef(), { items });
  dispatch(setCartItems(items));
  dispatch(blockUi(false));
};

export const fetchCartIfNeeded = () => async (dispatch, getState) => {
  const state = getState();
  if(!state.user?.uid || state.user.isAnonymous) {
    return dispatch(resetCart());
  }
  if(state.cart.fetching) return;
  if(state.cart.fetched) return;
  
  dispatch(requestCart());
  dispatch(setCartItems((await getDoc(getCartRef())).data()?.items || []));
};

export const removeCartItem = productId => async (dispatch, getState) => {
  const state = getState();
  const items = state.cart.items.filter(item => item.productId !== productId);
  dispatch(setCartItems(items));
  await setDoc(getCartRef(), { items });
};

let saveCart;
export const updateCartItem = (productId, quantity) => async (dispatch, getState) => {
  clearTimeout(saveCart);
  const state = getState();
  const items = state.cart.items.map(item => {
    if(item.productId === productId)
      item.quantity = quantity || 1;
    return item;
  });
  saveCart = setTimeout(() => {
    setDoc(getCartRef(), { items })
  }, 250);
  dispatch(setCartItems(items));
}

const setCartItems = items => ({
  type: SET_CART_ITEMS,
  items
});

export const requestCart = () => ({ type: REQUEST_CART });
export const resetCart = () => async (dispatch, getState) => {
  const state = getState();
  if(state.cart.items.length)
    await deleteDoc(getCartRef());
  dispatch({ type: RESET_CART });
};