import { connect } from 'react-redux';
import ProfileComponent from './ProfileComponent';
import { blockUi, fetchCardsIfNeeded, showAlert, updateEmail, updatePassword, updateProfile } from '../../actions';
import { verify } from '../../constants/routes';
import { formIsValid } from '../../utils';

const onEmailSubmit = async (e, dismiss, dispatch, navigate) => {

  if(!formIsValid(e))
    return;
  
  const email1 = e.target.email1;
  const email2 = e.target.email2;

  if(email1.value !== email2.value){
    email2.setCustomValidity('emails must match');
    email2.reportValidity();
  } else {
   email2.setCustomValidity('');
   email2.reportValidity();
  }

  if(!e.target.checkValidity())
    return;

  dismiss();
  dispatch(blockUi(true));
  try {
    await dispatch(updateEmail(email1.value, e.target.password.value));
    navigate(verify);
    dispatch(showAlert('success', 'Your email has been updated.'));
  } catch(e) {
    dispatch(showAlert('danger', e.message));
  }
  dispatch(blockUi(false));  

};

const onNameSubmit = async (e, dismiss, dispatch) => {

  if(!formIsValid(e))
    return;

  dismiss();
  dispatch(blockUi(true));
  try {
    await dispatch(updateProfile({ displayName: e.target.displayName.value }));
    dispatch(showAlert('success', 'Your name has been updated'));
  } catch (e) {
    dispatch(showAlert('danger', e.message));
  }
  dispatch(blockUi(false));

};

const onPasswordSubmit = async (e, dismiss, dispatch) => {

  if(!formIsValid(e))
    return;
  
  const password1 = e.target.newPassword;
  const password2 = e.target.confirmPassword;

  if(password1.value !== password2.value){
    password2.setCustomValidity('Passwords must match');
    password2.reportValidity();
  } else {
    password2.setCustomValidity('');
  }

  if(!e.target.checkValidity())
    return;

  dismiss();
  dispatch(blockUi(true));
  try {
    await dispatch(updatePassword(e.target.currentPassword.value, password1.value));
    dispatch(showAlert('success', 'Your password has been updated.'));
  } catch(e) {
    dispatch(showAlert('danger', e.message));
  }
  dispatch(blockUi(false));  

};

const mapStateToProps = state => ({
  cards: state.cards.items,
  user: state.user
});

const mapDispatchToProps = (dispatch, props) => ({
  fetchCards: () => dispatch(fetchCardsIfNeeded()),
  onEmailSubmit: (e, dismiss, navigate) => onEmailSubmit(e, dismiss, dispatch, navigate),
  onNameSubmit: (e, dismiss) => onNameSubmit(e, dismiss, dispatch),
  onPasswordSubmit: (e, dismiss) => onPasswordSubmit(e, dismiss, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileComponent);
