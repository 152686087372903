import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { ImageUpload, PaymentForm } from '../';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Progress,
  Row
} from 'reactstrap';
import { orderTypeNames } from '../../constants/orderTypes';
import { NEW, statusColors } from '../../constants/orderStatuses';
import { orders } from '../../constants/routes';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { KW_CUSTOMER, REDFIN_CUSTOMER } from '../../constants/roles';

const EditOrder = ({
  fetchOrder,
  files,
  noPaymentGatewayAlert,
  onAddressSubmit,
  onCancelOrderClick,
  onDeleteFilesBtnClick,
  onFilesChange,
  onFilesSubmit,
  finalizeOrder,
  notificationEmailAddresses,
  notificationEmailFormSubmit,
  order,
  removeNotificationEmail,
  requestRevision,
  resetFilesForProduct,
  role,
  showDownload
  }) => {

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    fetchOrder(params.id);
    if(!order.id) {
      document.getElementById('addressForm').reset();
    }
    setAddressOpen(order.address.street1 ? false : true);
    setUploadsOpen(order.address.street1 && !order.zipURL ? true : false);
    setFinalizeOpen(order.address.street1  && order.zipURL ? true : false);
  }, [fetchOrder, order, files, params.id]);

  const [addressOpen, setAddressOpen] = useState(false);
  const [uploadsOpen, setUploadsOpen] = useState(false);
  const [finalizeOpen, setFinalizeOpen] = useState(false);
  const [isDragging, setIsDragging] = useState({});
  const [progress, setProgress] = useState('');
  // const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [revisionModalOpen, setRevisionModalOpen] = useState(false);
  // const [processing, setProcessing] = useState(false);
  const [isAddingEmailAddress, setIsAddingEmailAddress] = useState(false);

  // const togglePaymentModal = () => {
  //   return processing ? '' : setPaymentModalOpen(!paymentModalOpen);
  // }
  const toggleCancelModal = () => {
    return setCancelModalOpen(!cancelModalOpen);
  }
  const toggleRevisionModal = () => {
    return setRevisionModalOpen(!revisionModalOpen);
  }

  const preventEvent = e => {
    e.preventDefault();
    e.stopPropagation();
  }
  const dragEvent = (e, productId, dragging) => {
    preventEvent(e);
    setIsDragging({ [productId]: dragging });
  }
  const paymentModal = useRef(null);

  const getOrderTotal = () => order.price && order.quantity ? 
    (parseFloat(order.price) * parseInt(order.quantity)).toFixed(2) : 
    Object.values(order.products).reduce((total, product) => {
      return total + product.price;
    }, 0).toFixed(2);

  return (
    <Row>
      <Col>
        <div className="d-flex align-items-start">
          <h3 className="d-inline-block">Order</h3>
          <Badge color={statusColors[order.status]} className="ms-2">{order.status}</Badge>
        </div>
        {order.status === NEW ? <Card className="shadow-sm">
          <CardHeader className="cursor-pointer" onClick={() => setAddressOpen(!addressOpen)}>1. Property Address for Order</CardHeader>
          <Collapse isOpen={addressOpen}>
            <CardBody>
              <Form noValidate id='addressForm' onSubmit={e => onAddressSubmit(e, navigate)}>
                <FormGroup>
                  <Label for="street1" className="required">Street</Label>
                  <Input type="text" name="street1" id="street1" placeholder="12345 Street" required defaultValue={order.address.street1} />
                  <Input className="mt-2" type="text" name="street2" id="street2" placeholder="apt #, suite, etc." defaultValue={order.address.street2} />
                </FormGroup>
                <Row>
                  <Col md="7">
                    <FormGroup>
                      <Label for="city" className="required">City</Label>
                      <Input type="text" name="city" id="city" placeholder="City" required defaultValue={order.address.city} />
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup>
                      <Label for="state" className="required">State</Label>
                      <Input type="text" name="state" id="state" placeholder="ST" required defaultValue={order.address.state} />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label for="zip" className="required">Zip</Label>
                      <Input type="text" name="zip" id="zip" placeholder="12345" required defaultValue={order.address.zip} />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <Label for="notes">Notes</Label>
                  <Input type="textarea" name="notes" id="notes" key="notes" aria-describedby="notes" placeholder="notes or directions about your order" defaultValue={order.notes} />
                </FormGroup>
                <Button className="btn-vrx mt-3">Save</Button>
              </Form>
            </CardBody>
          </Collapse>
        </Card> : ''}
        {order.status === NEW ? <Card className="shadow-sm mt-3">
          <CardHeader id='cardHeader' className={order.address.street1 ? 'cursor-pointer' : ''} onClick={() => order.address.street1 ? setUploadsOpen(!uploadsOpen) : ''}>2. Upload Photos</CardHeader>
          <Collapse isOpen={uploadsOpen}>
            <CardBody>
              {order.zipURL ?
                <div className="order-upload-complete">
                  <div className="previews">
                    {Object.entries(order.products).map(entry => <div key={`image-list-${entry[0]}`} className="mb-2">
                        <h4>{entry[1].name}</h4>
                        {files.map((file, imgNum) => file.productId === entry[0] ? 
                            <div key={`image-list-${entry[0]}-${imgNum}`} 
                            className="d-inline-block finalize-images__image me-3 rounded shadow-sm"
                            style={{ backgroundImage:`url(${file.dataURL || file.downloadURL})` }} /> : null
                          )}
                    </div>)}
                  </div>
                  <Button className="btn-vrx mt-2" onClick={e => {
                    setProgress('');
                    onDeleteFilesBtnClick(e);
                  }}>Change</Button>
                </div> : '' 
              }
              {progress !== '' && progress !== 'complete' ?
                <div className="order-upload-progress">
                  <Progress animated color="black" value={progress}>{progress}%</Progress>
                  <h4 className="text-center mt-3 mb-0">Please, wait while your photos upload.</h4>
                </div> : ''
              }
              {progress === '' && !order.zipURL ?
                <Form onSubmit={e => onFilesSubmit(e, setProgress)}>
                  {Object.entries(order.products).map(entry => <div key={`order-product-${entry[0]}`}>
                    {!files.find(({ productId }) => productId === entry[0]) ?
                      <FormGroup
                        onDrag={preventEvent}
                        onDragStart={preventEvent}
                        onDragEnter={e => dragEvent(e, entry[0], true)}
                        onDragOver={e => dragEvent(e, entry[0], true)}
                        onDragEnd={e => dragEvent(e, entry[0], false)}
                        onDragLeave={e => dragEvent(e, entry[0], false)}
                        onDrop={e => {
                          dragEvent(e, entry[0], false);
                          onFilesChange(e, entry[0]);
                        }}
                        className={`dropbox ${isDragging[entry[0]] ? 'dragging' : ''}`}
                      >
                        <Input type="file" name={`${entry[0]}-files[]`} 
                        id={`${entry[0]}-files`} accept="image/*" multiple
                        onChange={e => onFilesChange(e, entry[0])}></Input>
                        <Label for={`${entry[0]}-files`}>
                          <h4>{entry[1].name}</h4>
                          <strong>Choose {entry[1].quantity} files</strong>
                        </Label>
                        <span className="or">or</span>
                        <p>drag them here</p>
                      </FormGroup> : <div className="py-4 mb-3 border border-2">
                        <h4 className="text-center">{entry[1].name}</h4>
                        <div className="d-flex justify-content-center">
                          {files.map((file, imgNum) => file.productId === entry[0] ? 
                            <ImageUpload key={`image-upload-${imgNum}`} imgNum={imgNum} /> : null
                          )}
                        </div>
                        <div className="text-end pe-3">
                          <Button color="danger" onClick={e => resetFilesForProduct(entry[0])}
                            disabled={files.filter(file => file.productId === entry[0]).reduce((disable, file) => file.downloadURL ? disable : true, false)}>reset</Button>
                        </div>
                      </div> }
                    </div>
                    )
                  }
                  <Button className="btn-vrx mt-3" disabled={files.filter(file => file.downloadURL).length !== Object.values(order.products).reduce((qty, product) => qty + product.quantity, 0)}>Save</Button>
                </Form>
                : ''
              }
            </CardBody>
          </Collapse>
        </Card> : ''}
        <Card className="shadow-sm my-3">
          <CardHeader className={order.zipURL && order.status === NEW ? 'cursor-pointer' : ''} onClick={() => order.zipURL && order.status === NEW ? setFinalizeOpen(!finalizeOpen) : ''}>{order.status === NEW ? '3. Review and Finalize' : 'Review Order'}</CardHeader>
          <Collapse isOpen={finalizeOpen}>
            <CardBody>
            {order.status === NEW ? <Row className="mb-4">
                <Col>
                  {notificationEmailAddresses?.length ? <p>Notifications will be sent to these email addresses:</p> : <p>Notifications will be sent to this email address:</p>}
                  <ListGroup>
                    <ListGroupItem>{order.customerEmail}</ListGroupItem>
                    {notificationEmailAddresses.map((email, index) =>
                      <ListGroupItem key={`notification-email-${index}`}>
                        {email}
                        <Button className="float-end" size="sm" onClick={() => removeNotificationEmail(index)}>Remove</Button>
                      </ListGroupItem>
                    )}
                    {isAddingEmailAddress ?  <ListGroupItem>
                      <Form noValidate onSubmit={e => { 
                        notificationEmailFormSubmit(e);
                        setIsAddingEmailAddress(false);
                      }}>
                        <InputGroup>
                          <Input type="email" autoFocus required name="email"></Input>
                          <Button onClick={() => setIsAddingEmailAddress(false)}>Cancel</Button>
                          <Button className="btn-vrx">Save</Button>
                        </InputGroup>
                      </Form>
                    </ListGroupItem> : ''}
                  </ListGroup>
                  {notificationEmailAddresses?.length ? '' : <p className="my-2"><strong>If you would like order notifications and completed files to be sent to an additional email please click the Add Email button below.</strong></p>}
                  <Button className="btn-vrx mt-3" size="sm" onClick={() => {
                    setIsAddingEmailAddress(true);
                  }} disabled={isAddingEmailAddress}>Add Email</Button>
                  <div className="mt-3 border-bottom"></div>
                </Col>
              </Row> : ''}
              <Row>
                <Col md="5" lg="4" className="border-md-end">
                  <Row tag="dl" className="mb-0">
                    <Col md="4" tag="dt" className="px-lg-3 px-md-2">Address</Col>
                    <Col md="8" tag="dd" className="px-lg-3 px-md-2">
                      <div>{order.address.street1}</div>
                      {order.address.street2 ? <div>{order.address.street2}</div> : ''}
                      <div>{order.address.city}, {order.address.state} {order.address.zip}</div>
                    </Col>
                  </Row>
                  {order.notes ? <Row tag="dl" className="mb-0">
                    <Col md="4" tag="dt" className="px-lg-3 px-md-2">Notes</Col>
                    <Col md="8" tag="dd" className="px-lg-3 px-md-2">{order.notes}</Col>
                  </Row> : ''}
                  {role !== KW_CUSTOMER && role !== REDFIN_CUSTOMER ? <Row tag="dl" className="mb-0">
                    <Col md="4" tag="dt" className="px-lg-3 px-md-2">Total</Col>
                    <Col md="8" tag="dd" className="px-lg-3 px-md-2">
                      ${getOrderTotal()}
                      {/* ${order.price ? (parseFloat(order.price) * parseInt(order.quantity)).toFixed(2) : '0.00'} */}
                    </Col>
                  </Row> : ''}
                  {order.status === NEW ? <Row className="d-md-none mb-4">
                    <Col>
                      <Button className="btn-vrx" size="sm" onClick={() => {
                        setAddressOpen(true);
                        setUploadsOpen(false);
                        setFinalizeOpen(false);
                      }}>Change</Button>
                    </Col>
                  </Row> : ''}
                </Col>
                <Col md="7" lg="8">
                  <Row tag="dl">
                    <Col md="3" lg="2" tag="dt" className="px-lg-3 px-md-2">Photos</Col>
                    <Col md="9" lg="10" tag="dd" className="finalize-images mb-0 px-lg-3 px-md-1">
                      <table>
                        <tbody>
                        {Object.entries(order.products).map((entry, index, arr) => <tr key={`finalize-image-list--${entry[0]}`}>
                          <td className={`pb-2 ${index > 0 ? 'border-top pt-3': ''}`}>
                            <p><strong>{entry[1].name}</strong></p>
                            <table>
                              <tbody>
                              {files.map((file, imgNum) => file.productId === entry[0] ? 
                                <tr key={`finalize-image-list-${imgNum}`}>
                                  <td>
                                    <div className="finalize-images__image me-3 mb-2 rounded shadow-sm"
                                      style={{ backgroundImage:`url(${file.dataURL || file.downloadURL})` }}></div>
                                  </td>
                                  <td className="align-top"><strong>{file.name}</strong><br/>{file.note}</td>
                                </tr> : null
                              )}
                              </tbody>
                            </table>
                          </td>
                        </tr>)}
                          {/* {files.map((obj, imgNum) =>
                            <tr key={`finalize-image-list-${imgNum}`}>
                              <td>
                                <div className="finalize-images__image me-3 mb-2 rounded shadow-sm"
                                  style={{ backgroundImage:`url(${obj.dataURL || obj.downloadURL})` }}></div>
                              </td>
                              <td className="align-top"><strong>{obj.name}</strong><br/>{obj.note}</td>
                            </tr>
                          )} */}
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                  {order.status === NEW ? <Row className="d-md-none">
                    <Col>
                      <Button className="btn-vrx" size="sm" onClick={e => {
                        setProgress('');
                        onDeleteFilesBtnClick(e);
                      }}>Change</Button>
                    </Col>
                  </Row> : ''}
                </Col>
              </Row>
              {order.status === NEW ? <Row className="d-none d-md-flex">
                <Col md="5" lg="4" className="border-end">
                  <Button className="btn-vrx" size="sm" onClick={() => {
                    setAddressOpen(true);
                    setUploadsOpen(false);
                    setFinalizeOpen(false);
                  }}>Change</Button>
                </Col>
                <Col md="7" lg="8">
                  <Button className="btn-vrx" size="sm" onClick={e => {
                    setProgress('');
                    onDeleteFilesBtnClick(e);
                  }}>Change</Button>
                </Col>
              </Row> : ''}
              {order.status === NEW ? <Row className="mt-4">
                <Col>
                  <h4 className="border-top pt-4">Review your order.</h4>
                  <p>If everything is correct, click the "Finalize Order" button below.</p>
                  <Button className="btn-vrx" onClick={_e => {
                    if (role === KW_CUSTOMER || role === REDFIN_CUSTOMER || !getOrderTotal())
                      return finalizeOrder(navigate);
                    // togglePaymentModal();
                    console.log('noPaymentGatewayAlert');
                    noPaymentGatewayAlert();
                  }}>Finalize Order</Button>
                </Col>
              </Row> : ''}
              {showDownload ? <Row className="mt-4">
                <Col>
                  <h4 className="border-top pt-4">Your order is complete.</h4>
                  <p>To download your files, click the "Download" button below.</p>
                  <p className="d-flex justify-content-between">
                    <a href={order.processedZipURL} className="btn btn-vrx">Download</a>
                    <Button className="btn btn-vrx" onClick={toggleRevisionModal}>Request a revision </Button>
                  </p> 
                </Col>
              </Row> : ''}
            </CardBody>
          </Collapse>
        </Card>
        <p className="mt-3 pt-2 d-flex justify-content-between">
          <Link to={orders} className="btn btn-vrx">&#8678; All Orders</Link>
          {role !== KW_CUSTOMER && order.status === NEW ? <Button color="danger" onClick={toggleCancelModal}>Cancel this order</Button>: ''}
        </p>
      </Col>
      
      {/* <Modal ref={paymentModal} isOpen={paymentModalOpen} backdrop="static" keyboard={false} toggle={togglePaymentModal}>
        <ModalHeader toggle={togglePaymentModal}>Payment</ModalHeader>
        <PaymentForm togglePaymentModal={togglePaymentModal} isOpen={paymentModalOpen} processing={processing} setProcessing={setProcessing} />
      </Modal> */}

      <Modal isOpen={cancelModalOpen} toggle={toggleCancelModal}>
        <ModalHeader toggle={toggleCancelModal}>Are you sure?</ModalHeader>
        <ModalBody>Are you sure you want to cancel this order?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={ () => onCancelOrderClick(navigate, toggleCancelModal)}>Yes</Button>{' '}
          <Button color="secondary" onClick={toggleCancelModal}>No</Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={revisionModalOpen} toggle={toggleRevisionModal}>
        <Form onSubmit={e => requestRevision(e, navigate, toggleRevisionModal)}>
          <ModalHeader toggle={toggleRevisionModal}>Request a revision</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="revisionText">Please tell us what you would like changed</Label>
              <Input id="revisionText" name="revision" type="textarea" />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="btn btn-vrx">Send</Button>{' '}
            <Button color="secondary" onClick={toggleRevisionModal}>Cancel</Button>
          </ModalFooter>
        </Form>
      </Modal>

    </Row>
  );
};

EditOrder.propTypes = {
  fetchOrder: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
  onAddressSubmit: PropTypes.func.isRequired,
  onCancelOrderClick: PropTypes.func.isRequired,
  onDeleteFilesBtnClick: PropTypes.func.isRequired,
  onFilesChange: PropTypes.func.isRequired,
  onFilesSubmit: PropTypes.func.isRequired,
  finalizeOrder: PropTypes.func.isRequired,
  notificationEmailAddresses: PropTypes.array.isRequired,
  notificationEmailFormSubmit: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  removeNotificationEmail: PropTypes.func.isRequired,
  requestRevision: PropTypes.func.isRequired,
  resetFilesForProduct: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  showDownload: PropTypes.bool.isRequired
};

export default EditOrder;