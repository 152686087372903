const firebaseConfig = {
  apiKey: 'AIzaSyDTjeEmzhy72f7XKh9dToxGh38hEovGJUw',
  authDomain: 'vrx-photos.firebaseapp.com',
  databaseURL: 'https://vrx-photos.firebaseio.com',
  projectId: 'vrx-photos',
  storageBucket: 'vrx-photos.appspot.com',
  messagingSenderId: '951655256508',
  appId: '1:951655256508:web:d290577f8cb6326e011bb9'
}

export default firebaseConfig;