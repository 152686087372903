import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { render } from 'react-dom';
import { createStore, compose, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import reducers from './reducers';
import Root from './components/Root';
import './css/style.css';

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducers,
  storeEnhancers(applyMiddleware(reduxThunk)),  
);

render(
  <BrowserRouter>
    <Root store={store} />
  </BrowserRouter>, 
  document.getElementById('root')
);
