import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const CustomerOrderComponent = ({ logout }) => {
  useEffect(() => {
    logout();
  })
  return <div></div>;
};

CustomerOrderComponent.propTypes = {
  logout: PropTypes.func.isRequired
};

export default CustomerOrderComponent;