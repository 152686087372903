export const getFileList = e => {
  let fileList = [];
  if (e.dataTransfer) {
    const dt = e.dataTransfer;
    if (dt.files && dt.files.length) {
      fileList = dt.files;
    } else if (dt.items && dt.items.length) {
      fileList = dt.items;
    }
  } else if (e.target && e.target.files) {
    fileList = e.target.files;
  }
  return fileList;
};