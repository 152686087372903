import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import App from './App';
import * as screens from './screens';
import { routes }  from '../constants';
import { setUser } from '../actions';

const Root = ({ store }) => {
  return (
  <Provider store={store}>
    <Routes>
      <Route path={routes.home} element={<App/>}>
        <Route exact path={routes.cart} element={<screens.Cart/>} />
        <Route exact path={routes.customerOrders} element={<screens.CustomerOrders/>} />
        <Route exact path={routes.forgotPassword} element={<screens.ForgotPassword/>} />
        <Route exact path={routes.home} element={<screens.Home/>} />
        <Route exact path={routes.kwOrder} element={<screens.KWOrder/>} />
        <Route exact path={routes.loading} element={<screens.Loading/>} />
        <Route exact path={routes.login} element={<screens.Login/>} />
        <Route exact path={routes.logout} element={<screens.Logout/>} />
        <Route exact path={routes.order} element={<screens.EditOrder/>} />
        <Route exact path={routes.orders} element={<screens.Orders/>} />
        <Route exact path={routes.products} element={<screens.Products/>} />
        <Route exact path={routes.profile} element={<screens.Profile/>} />
        <Route exact path={routes.register} element={<screens.Register/>} />
        <Route exact path={routes.registerListReports} element={<screens.Register/>} />
        <Route exact path={routes.registerRedfin} element={<screens.Register/>} />
        <Route exact path={routes.verify} element={<screens.Verify/>} />
        <Route exact path={routes.vrxOrder} element={<screens.VRXOrder/>} />
      </Route>
    </Routes>
  </Provider>
)};

Root.propTypes = {
  setUser: PropTypes.func.isRequired,
  store: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    user: state.user
  }
};

const mapDispatchToProps = dispatch => {
  return {
    setUser: user => dispatch(setUser(user))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Root);
