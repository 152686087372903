import { SET_USER } from './types';
import { auth, functions, getAuthProvider } from '../constants/firebase';
import { httpsCallable } from 'firebase/functions';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, signInWithRedirect, updateProfile as _updateProfile, reload, sendEmailVerification as _sendEmailVerification, updateEmail as _updateEmail, updatePassword as _updatePassword, sendPasswordResetEmail as _sendPasswordResetEmail, signOut } from 'firebase/auth';

export const createEmailUser = async (email, password) => await createUserWithEmailAndPassword(auth, email, password);

export const createUserWithRole = async (email, password, role) => {
  const createUserWithRole = httpsCallable(functions, 'createUserWithRole');
  await createUserWithRole({ email, password, role });
  await loginWithEmailAndPassword(email, password);
};

export const loginWithEmailAndPassword = async (email, password) => 
  await signInWithEmailAndPassword(auth, email, password);

export const loginWithOAuth = async providerName => {
  const provider = getAuthProvider(providerName);
  if(window.screen.width <= 1024 || window.screen.height <= 1024) {
    return signInWithRedirect(auth, provider);
  }
  return signInWithPopup(auth, provider);
}

export const logout = () => {
  signOut(auth);
}

export const reloadUser = () => async dispatch => {
  await reload(auth.currentUser);
  await dispatch(refreshToken());
  return auth.currentUser;
};

export const refreshToken = () => async dispatch => {
  let user = auth.currentUser;
  const tokenResult = await user.getIdTokenResult(true);
  user.claims = tokenResult.claims;
  dispatch(setUser(user));
}

export const sendEmailVerification = async () => await _sendEmailVerification(auth.currentUser);

export const sendPasswordResetEmail = emailAddress => {
  return _sendPasswordResetEmail(auth, emailAddress);
}

export const setUser = user => ({
  type: SET_USER,
  user
});

export const updateEmail = (email, password) => async dispatch => {
  await loginWithEmailAndPassword(auth.currentUser.email, password);
  await _updateEmail(auth.currentUser, email);
  await dispatch(reloadUser());
  await sendEmailVerification();
}

export const updatePassword = (currentPassword, newPassword) => async dispatch => {
  await loginWithEmailAndPassword(auth.currentUser.email, currentPassword);
  await _updatePassword(auth.currentUser, newPassword);
  await dispatch(reloadUser());
}

export const updateProfile = profile => async dispatch => {
  await _updateProfile(auth.currentUser, profile);
  await dispatch(reloadUser());
};