import { initializeApp } from 'firebase/app';
import { getFunctions } from 'firebase/functions';
import { getStorage } from "firebase/storage";
import config from './firebaseConfig';
import { getItemsFromSnapshot } from '../utils';
import { getAuth, FacebookAuthProvider, GoogleAuthProvider, TwitterAuthProvider } from 'firebase/auth';
import { doc, getDoc, getDocs, getFirestore, collection, query, where } from 'firebase/firestore';

const firebaseApp = initializeApp(config);

export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);
export const functions = getFunctions(firebaseApp);
export const functionsURL = `https://us-central1-${config.projectId}.cloudfunctions.net`;
export const getAuthProvider = providerName => new { FacebookAuthProvider, GoogleAuthProvider, TwitterAuthProvider }[`${providerName}AuthProvider`]();
export const storage = getStorage(firebaseApp);

// orders
export const getOrderRef = id => doc(db, 'orders', id);
export const getOrder = async id => (await getDoc(getOrderRef(id))).data();
export const getOrders = async () => {
  const snapshot = await getDocs(query(ordersCollection, where('customerId', '==', auth.currentUser.uid)));
  return await getItemsFromSnapshot(snapshot);
}
export const ordersCollection = collection(db, 'orders');

// carts
export const cartsCollection = collection(db, 'carts');
export const getCartRef = () => doc(db, 'carts', auth.currentUser.uid); 