import React from 'react';
import PropTypes from 'prop-types';
// import OAuth from '../OAuth';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, CardImg, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { login, registerListReports, registerRedfin } from '../../constants/routes';

const Register = ({ onSubmit }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isListReports = location.pathname === registerListReports;
  const isRedfin = location.pathname === registerRedfin;
  return <Row className="register">
    <Col xl={{ size: 4, offset: 4 }} lg={{ size: 6, offset: 3 }} md="12">
      <Card body>
      {isListReports ? 
        <CardImg top src={`${process.env.PUBLIC_URL}/img/ListReportsLogo.png`} className="w-75 mt-4 mx-auto" alt="List Reports" title="List Reports" /> :
        isRedfin ? 
        <CardImg top src={`${process.env.PUBLIC_URL}/img/redfin_full_logo_1x.png`} className="mt-4 mx-auto" alt="Redfin" title="Redfin" /> :
        <CardImg top src={`${process.env.PUBLIC_URL}/img/vrx-media-group-logo_1x.png`} className="w-75 mt-4 mx-auto" alt="VRX Photo Edit" title="VRX Photo Edit" />
      }
        <h2 className="mt-4 mb-2">Register</h2>
        <Form onSubmit={e => onSubmit(e, location, navigate)} noValidate>
          <FormGroup>
            <Label for="email">email</Label>
            <Input type="email" name="email" id="email" placeholder="you@email.com" required/>
          </FormGroup>
          <FormGroup>
            <Label for="password">Password</Label>
            <Input type="password" name="password" id="password" placeholder="top secret password" pattern=".{6,}" required/>
          </FormGroup>
          <FormGroup>
            <Label for="confirmPassword">Confirm Password</Label>
            <Input type="password" name="confirmPassword" id="confirmPassword" placeholder="top secret password again" pattern=".{6,}" required onKeyDown={e => e.target.setCustomValidity('')}/>
          </FormGroup>
          <Button className="btn-vrx mt-3">submit</Button>
        </Form>
        {/* not ready for OAuth yet */}
        {/* {!isListReports && !isRedfin ? <OAuth /> : ''} */}
      </Card>
      <p className="text-center mt-3 pt-2">
        <Link to={login} className="gray-link">&#8678; Back to login</Link>
      </p>
    </Col>
  </Row>
};

Register.propTypes = {
  onSubmit: PropTypes.func.isRequired
};


export default Register;