import { auth, functions, functionsURL, getOrder, getOrders, getOrderRef, ordersCollection } from '../constants/firebase';
import { REQUEST_ORDERS, RESET_ORDER, RESET_ORDERS, SET_ORDER, SET_ORDERS } from './types';
import { newOrderId, order as orderRoute, orders } from '../constants/routes';
import { blockUi, showAlert, resetCards, resetCart } from './';
import { formatAddressToOneLine, getFormAsObject, sortArrayOfObjects } from '../utils';
import { CANCELED, NEW, REVISION } from '../constants/orderStatuses';
import { newOrder } from '../reducers/ordersReducer';
import { refreshToken } from './user';
import { addDoc, onSnapshot, updateDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { VRX_PHOTOS_ORDER } from '../constants/orderTypes';

export const addNotificationEmailAddress = email => (dispatch, getState) => {
  const order = getState().orders.order;
  let emails = order.notificationEmailAddresses?.slice() || [];
  emails.push(email);
  order.notificationEmailAddresses = emails;
  dispatch(setOrder(order));
};

export const cancelOrder = (navigate, toggleCancelModal) => async (dispatch, getState) => {
  toggleCancelModal();
  dispatch(blockUi(true));
  const diff = { status: CANCELED };
  const order = Object.assign({}, getState().orders.order, diff);
  const orderRef = getOrderRef(order.id);
  await updateDoc(orderRef, diff);

  const ordersItems = getState().orders.items;
  if(ordersItems.length)
    dispatch(setOrders(ordersItems.filter(_order => _order.id !== order.id)));
  dispatch(blockUi(false));
  dispatch(showAlert('success', 'Your order has been canceled'));
  navigate(orders);
};

export const createOrder = (navigate) => async (dispatch, getState) => {
  dispatch(blockUi(true));
  const state = getState();
  const cartItems = state.cart.items;
  let quantity = 0;
  // let price = 0;
  const products = cartItems.reduce((products, item) => {
    const product = state.products.items.find(product => product.id === item.productId);
    quantity += item.quantity;
    // price += parseFloat(product.price);
    return Object.assign(products, { [product.id]: {
      name: product.name,
      quantity: item.quantity,
      price: parseFloat(product.price)
    }});
  }, {});

  let order = Object.assign(newOrder, {
    products,
    quantity,
    customerEmail: state.user.email,
    customerId: state.user.uid,
    customerName: state.user.displayName,
    type: VRX_PHOTOS_ORDER
  });
  
  const orderDocRef = await addDoc(ordersCollection, order);
  
  await new Promise((resolve, reject) => {
    const unsubscribe = onSnapshot(orderDocRef, (doc) => {
      order = doc.data();
      if (order.oneHubFolder) {
        unsubscribe();
        return resolve(order);
      }
    }, error => {
      unsubscribe();
      dispatch(showAlert('danger', error.message));
      return reject(error);
    });
  });
  
  order.id = orderDocRef.id;
  dispatch(setOrder(order));
  dispatch(resetCart());
  
  if (state.orders.fetched) {
    const orders = [order].concat(state.orders.items);
    dispatch(setOrders(orders));
  }
  
  dispatch(blockUi(false));
  navigate(orderRoute.replace(/:id/, order.id));
  
};

export const fetchOrderIfNeeded = id => async (dispatch, getState) => {
  const state = getState();
  if(!state.user.verified) return;
  if(state.orders.order.id === id) return;
  if(id === newOrderId && state.orders.order.id) {
    return dispatch(resetOrder());
  }
  if(id === newOrderId) return;
  dispatch(blockUi(true));
  try {
    const order = await getOrder(id);
    order.id = id;
    dispatch(setOrder(order));
    dispatch(blockUi(false));
  } catch (e) {
    dispatch(blockUi(false));
    throw e;
  }
};

export const fetchOrdersIfNeeded = () => async (dispatch, getState) => {
  const state = getState();
  if(state.orders.fetching) return;
  if(state.orders.fetched) return;
  dispatch(blockUi(true));
  dispatch({ type: REQUEST_ORDERS });
  const orders = sortArrayOfObjects(await getOrders(), 'created')
    .reverse()
    .filter(order => order.status !== CANCELED);
  dispatch(setOrders(orders));
  dispatch(blockUi(false));
}

export const finalizeOrder = navigate => async (dispatch, getState) => {
  dispatch(blockUi(true, 'Please, wait while we process your order.'));
  const order = getState().orders.order;
  const transferFileToOneHub = httpsCallable(functions, 'transferFileToOneHub');
  const oneHubRes = await transferFileToOneHub({ orderId: order.id });
  const oneHubData = oneHubRes.data;
  if (!oneHubData || oneHubData.error) {
    dispatch(showAlert('danger', oneHubRes.data.error));
    dispatch(blockUi(false));
    return;
  }
  try{
    const orderRef = getOrderRef(order.id);
    await updateDoc(orderRef, {
      notificationEmailAddresses: order.notificationEmailAddresses,
      oneHubFile: oneHubData.file.id
    });
  } catch(e){
    dispatch(showAlert('danger', e.message));
    dispatch(blockUi(false));
    return;
  }

  try {
    const writeOrderToSheet = httpsCallable(functions, 'writeOrderToSheet');
    await writeOrderToSheet({ orderId: order.id });
  } catch (e) {
    dispatch(showAlert('danger', e.message));
    dispatch(blockUi(false));
    return;
  }

  dispatch(resetOrder());
  dispatch(resetOrders());
  dispatch(blockUi(false));
  dispatch(showAlert('success', 'Your order has been submitted'));
  navigate(orders);
}

export const confirmPaymentIntent = (body, setError, setProcessing) => async (dispatch, getState) => {
  setProcessing(true);
  const state = getState();
  const order = state.orders.order;
  try {
    const token = await auth.currentUser.getIdToken();
    const options = {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    };
    const res = await fetch(`${functionsURL}/vrx/payments`, options);
    const json = await res.json();
    if(json.error){
      setError(json.error);
    } else {
      setError('');
      order.paymentIntentStatus = json.status;
      dispatch(setOrder(order));
      if(body.saveCard){
        dispatch(resetCards());
      }
    }
  } catch (e) {
    setError(e.message);
  }
  setProcessing(false);
};

export const getPaymentIntent = togglePaymentModal => async (dispatch, getState) => {
  const state = getState();
  const order = state.orders.order;
  if (!order.paymentIntent) {
    try {
      const token = await auth.currentUser?.getIdToken();
      const options = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          orderId: order.id
        })
      };
      const res = await fetch(`${functionsURL}/vrx/payments`, options);
      const json = await res.json();
      order.paymentIntent = json.paymentIntent;
      dispatch(setOrder(order));
      if(!state.user.claims.stripeId)
        await dispatch(refreshToken());
    } catch (e) {
      togglePaymentModal();
      dispatch(showAlert('danger', e.message));
    }
  }
};

export const removeNotificationEmail = index => (dispatch, getState) => {
  const order = getState().orders.order;
  order.notificationEmailAddresses.splice(index, 1);
  order.notificationEmailAddresses = order.notificationEmailAddresses.slice();
  dispatch(setOrder(order));
};

export const requestRevision = (revision, navigate, toggleRevisionModal) => async(dispatch, getState) => {
  toggleRevisionModal();
  let order = getState().orders.order;
  const revisions = [revision].concat(order.revisions || []);
  dispatch(blockUi(true));
  const diff = { revisions, status: REVISION };
  order = Object.assign({}, getState().orders.order, diff);
  const orderRef = getOrderRef(order.id);
  await updateDoc(orderRef, diff);
  const ordersItems = getState().orders.items;
  if(ordersItems.length)
    dispatch(setOrders(ordersItems.map(_order => _order.id === order.id ? order : _order)));
  const sendRevisionEmail = httpsCallable(functions, 'sendRevisionEmail');
  await sendRevisionEmail({
    address: formatAddressToOneLine(order.address),
    email: order.customerEmail,
    revision
  });
  dispatch(blockUi(false));
  dispatch(showAlert('success', 'Your revision has been requested'));
  navigate(orders);
};

export const resetOrder = () => ({ type: RESET_ORDER });
export const resetOrders = () => ({ type: RESET_ORDERS });

export const saveOrderAddress = addressForm => async (dispatch, getState) => {

  const address = getFormAsObject(addressForm);
  const notes = address.notes;
  const diff = {
    notes,
    address,
    customerId: auth.currentUser.uid
  };

  delete address.notes;
  
  const order = Object.assign({}, getState().orders.order, diff);
  
  let orderRef;

  if (order?.id) {
    orderRef = getOrderRef(order.id);
    await updateDoc(orderRef, diff);
  } else {
    order.status = NEW;
    orderRef = await addDoc(ordersCollection, order);
  }

  const orderData = await getOrder(orderRef.id);
  orderData.id = orderRef.id;

  dispatch(setOrder(orderData));
  dispatch(resetOrders());

  return orderRef.id;

};

export const setOrder = order => dispatch => {
  dispatch({
    type: SET_ORDER,
    order
  });
};

export const setOrders = orders => dispatch => {
  dispatch({
    type: SET_ORDERS,
    orders
  });
};