import { connect } from 'react-redux';
import RegisterComponent from './RegisterComponent';
import { blockUi, createEmailUser, createUserWithRole, showAlert, sendEmailVerification } from '../../actions';
import { registerListReports, registerRedfin, verify } from '../../constants/routes';

const onSubmit = async (e, dispatch, location, navigate) => {

  e.preventDefault();
  e.stopPropagation();
  e.target.classList.add('was-validated');

  const email = e.target.email.value;
  const p1 = e.target.password.value;
  const p2 = e.target.confirmPassword.value;
  const valid = e.target.checkValidity();

  if(p1 !== p2) {
    e.target.confirmPassword.setCustomValidity('Passwords need to match');
    return dispatch(showAlert('danger', 'Passwords do not match.'));
  }

  if(!valid) {
    return;
  }

  dispatch(blockUi(true));

  try {
    let role = null;
    if (location.pathname === registerListReports)
      role = 'listReports';
    if (location.pathname === registerRedfin || email.split('@')[1].toLowerCase().indexOf('redfin') >= 0)
      role = 'redfin';
    if (role) {
      await createUserWithRole(email, p1, role);
    } else {
      await createEmailUser(email, p1);
    }
    await sendEmailVerification();
    navigate(verify);
  } catch(err) {
    dispatch(showAlert('danger', err.message));
  }
  
  dispatch(blockUi(false));

}

const mapDispatchToProps = (dispatch, props) => ({
  onSubmit: (e, location, navigate) => onSubmit(e, dispatch, location, navigate)
});

export default connect(
  null,
  mapDispatchToProps
)(RegisterComponent);