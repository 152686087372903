import { connect } from 'react-redux';
import LogoutComponent from './LogoutComponent';
import { logout } from '../../actions';

const mapDispatchToProps = () => ({
  logout
});

export default connect(
  null,
  mapDispatchToProps
)(LogoutComponent);