import { BLOCK_UI, UNBLOCK_UI } from '../actions/types';
const initialState = {
  blocked: false,
  message: ''
};

const blockUiReducer = ( state = initialState, action ) => {

  if (action.type === BLOCK_UI) {
    return Object.assign({}, { blocked: true, message: action.message });
  }

  if (action.type === UNBLOCK_UI) {
    return Object.assign({}, initialState);
  }

  return state;
  
}; 
export default blockUiReducer;