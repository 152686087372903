import { connect } from 'react-redux';
import LoginComponent from './LoginComponent';
import { blockUi, loginWithEmailAndPassword, showAlert } from '../../actions';
import { products } from '../../constants/routes';

const onSubmit = async (e, dispatch, navigate, searchParams) => {
  
  e.preventDefault();
  e.stopPropagation();
  e.target.classList.add('was-validated');
  
  const email = e.target.email.value;
  const password = e.target.password.value;
  const valid = e.target.checkValidity();

  if(!valid) {
    return;
  }

  dispatch(blockUi(true));

  try{
    await loginWithEmailAndPassword(email, password);
    navigate(searchParams.get('redirect') || products);
  } catch (err) {
    dispatch(showAlert('danger', 'Authentication failed. Please, check your email and password and try again.'));
  }

  dispatch(blockUi(false));

}

const mapDispatchToProps = dispatch => ({
  onSubmit: (e, navigate, searchParams) => onSubmit(e, dispatch, navigate, searchParams)
});

export default connect(
  null,
  mapDispatchToProps
)(LoginComponent);