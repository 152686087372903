import React from 'react';
import PropTypes from 'prop-types';

const Alert = ({ color, className, close, text }) => (
  <div className={`alert alert-${color} alert-dismissible ${className}`} role="alert">
    {text}
    <button type="button" className="btn-close" aria-label="Close" onClick={close}></button>
  </div>
);

Alert.propTypes = {
  color: PropTypes.string.isRequired,
  className: PropTypes.string,
  close: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired
};

export default Alert;
