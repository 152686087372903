import { ADD_CART_ITEM, UPDATE_CART_ITEM, REQUEST_CART, RESET_CART, SET_CART_ITEMS } from "../actions/types";

const initialState = {
  fetched: false,
  fetching: false,
  items: []
};

const cartReducer = (state = initialState, action) => {

  if (action.type === ADD_CART_ITEM) {
    return Object.assign({}, state, { items: state.items.concat(action.item)});
  }

  if (action.type === REQUEST_CART) {
    return Object.assign({}, state, { fetching: true });
  }

  if (action.type === SET_CART_ITEMS) {
    return Object.assign({}, state, { 
      fetched: true,
      fetching: false,
      items: action.items
    });
  }

  if (action.type === UPDATE_CART_ITEM) {
    const items = state.items.map(item => {
      if(item.productId === action.item.productId)
        item.quantity += action.item.quantity;
      return item;
    });
    return Object.assign({}, state, { items });
  }

  if (action.type === RESET_CART)
    return Object.assign({}, initialState);

  return state;
  
};

export default cartReducer