import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Badge, Col, Container, List, Row, Table } from 'reactstrap';
import { formatAddressToOneLine } from '../../utils';
import { statusColors } from '../../constants/orderStatuses';
import { useNavigate } from 'react-router-dom';

const OrdersComponent = ({ fetchOrders, onOrderClick, ordersArray }) => {
  
  const navigate = useNavigate();
  
  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);
  
  return (
    <Container>
      <Row>
        <Col>
          <h1>Orders</h1>
        </Col>
      </Row>
      <Table hover responsive>
        <thead>
          <tr>
            <th>Address</th>
            <th>Date Ordered</th>
            <th>Product</th>
            <th>Quantity</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {!ordersArray.length ? <tr>
            <td colSpan="5">No orders yet</td>
          </tr> : ordersArray.map((order, index) => {
            const address = formatAddressToOneLine(order.address);
            const date = new Date(order.created);
            return <tr onClick={() => onOrderClick(navigate, order)} key={`order-${index}`} className="cursor-pointer">
              <td>{address}</td>
              <td>{`${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`}</td>
              {/* <td>{orderTypeNames[order.type]}</td> */}
              <td>
                <List type="unstyled">
                  {Object.entries(order.products || {}).map(entry => <li key={`order-${index}_product-${entry[0]}`}>
                    {entry[1].name}
                  </li>)}
                </List>
              </td>
              <td>{order.quantity}</td>
              <td><Badge color={statusColors[order.status]}>{order.status}</Badge></td>
            </tr>
          })}
        </tbody>
      </Table>
    </Container>
  );
};

OrdersComponent.propTypes = {
  fetchOrders: PropTypes.func.isRequired,
  onOrderClick: PropTypes.func.isRequired,
  ordersArray: PropTypes.array.isRequired
};

export default OrdersComponent;