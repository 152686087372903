import { combineReducers } from 'redux';
import customerOrders from './customerOrdersReducer';
import alert from './alertReducer';
import blockUi from './blockUiReducer';
import cards from './cardsReducer';
import cart from './cartReducer';
import orders from './ordersReducer';
import products from './productReducer';
import user from './userReducer';
export default combineReducers({
  alert,
  blockUi,
  cards,
  cart,
  customerOrders,
  orders,
  products,
  user
});