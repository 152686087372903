// alerts
export const DISMISS_ALERT = 'DISMISS_ALERT';
export const FADE_ALERT = 'FADE_ALERT';
export const SHOW_ALERT = 'SHOW_ALERT';

// block ui
export const BLOCK_UI = 'BLOCK_UI';
export const UNBLOCK_UI = 'UNBLOCK_UI';

export const ADD_CART_ITEM = 'ADD_CART_ITEM';
export const UPDATE_CART_ITEM = 'UPDATE_CART_ITEM';
export const REQUEST_CART = 'REQUEST_CART';
export const RESET_CART = 'RESET_CART';
export const SET_CART_ITEMS = 'SET_CART_ITEMS';

// cards
export const REQUEST_CARDS = 'REQUEST_CARDS';
export const RESET_CARDS = 'RESET_CARDS';
export const SET_CARDS = 'SET_CARDS';
export const SET_CARD = 'SET_CARD';

// orders 
export const RESET_ORDER = 'RESET_ORDER';
export const SET_ORDER = 'SET_ORDER';
export const SET_ORDERS = 'SET_ORDERS';
export const REQUEST_ORDERS = 'REQUEST_ORDERS';
export const RESET_ORDERS = 'RESET_ORDERS';

// orders (admin)
export const RESET_CUSTOMER_ORDER = 'RESET_CUSTOMER_ORDER';
export const SET_CUSTOMER_ORDER = 'SET_CUSTOMER_ORDER';
export const SET_CUSTOMER_ORDERS = 'SET_CUSTOMER_ORDERS';
export const SET_FILTERED_CUSTOMER_ORDERS = 'SET_FILTERED_CUSTOMER_ORDERS';
export const REQUEST_CUSTOMER_ORDERS = 'REQUEST_CUSTOMER_ORDERS';
export const RESET_CUSTOMER_ORDERS = 'RESET_CUSTOMER_ORDERS';

// products
export const REQUEST_PRODUCTS = 'REQUEST_PRODUCTS';
export const SET_PRODUCT = 'SET_PRODUCT';
export const SET_PRODUCTS = 'SET_PRODUCTS';

// user
export const SET_USER = 'SET_USER';