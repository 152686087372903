import { connect } from 'react-redux';
import VerifyComponent from './VerifyComponent';
import { blockUi, reloadUser, showAlert, sendEmailVerification } from '../../actions';

const onProductsLinkClick = async (dispatch, e, navigate) => {
  const href = e.currentTarget.attributes.href.value;
  e.preventDefault();
  dispatch(blockUi(true)); 
  const user = await dispatch(reloadUser());
  if(!user.emailVerified){
    dispatch(showAlert('danger', 'You still need to verify your email.'));
  }
  dispatch(blockUi(false)); 
  navigate(href);
}

const onResendEmailLinkClick = async (dispatch, e) => {
  e.preventDefault();
  dispatch(blockUi(true)); 
  try { 
    await sendEmailVerification();
    dispatch(showAlert('success', 'A verification email has been sent. Please, check your inbox.'));
  } catch (err) {
    dispatch(showAlert('danger', err.message));
  }
  dispatch(blockUi(false)); 
}

const mapDispatchToProps = dispatch => ({
  onProductsLinkClick: (e, navigate) => onProductsLinkClick(dispatch, e, navigate),
  onResendEmailLinkClick: e => onResendEmailLinkClick(dispatch, e)
});

export default connect(
  null,
  mapDispatchToProps
)(VerifyComponent);