import { SET_USER } from '../actions';

const initialState = { 
  checkedForRedirect: false,
  claims: {
    accessLevel: 0,
    role: '',
    stripeId: ''
  },
  multiFactor: {},
    emailVerified: false,
    isAnonymous: true,
    providerData: [{
      providerId: ''
    }],
    verified: false
};

const isVerified = user => {
  if (user.isAnonymous) {
    return false;
  }
  if (user.emailVerified) {
    return true;
  }
  switch (user.providerData[0]?.providerId) {
    case 'twitter.com':
    case 'facebook.com':
    case 'google.com':
      return true;
    default: 
      return false;
  }
}

export const userReducer = (state = initialState, action) => {

  switch(action.type){
    case SET_USER:
      return action.user ? Object.assign({ verified: isVerified(action.user) }, action.user) : initialState;
    default: 
      return state;
      
  }
  
};

export default userReducer;