import React from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Card, CardBody, CardImg, CardTitle, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
// import OAuth from '../OAuth';
import { forgotPassword, register } from '../../constants/routes';

const Login = ({ onSubmit  }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  return (
  <Row className="login">
    <Col xl={{ size: 4, offset: 4 }} lg={{ size: 6, offset: 3 }} md="12">
      <Card>
        <CardBody>
          <div className="text-center">
            <CardImg top src={`${process.env.PUBLIC_URL}/img/vrx-media-group-logo_1x.png`} className="w-75 mt-4 mx-auto" alt="VRX Photo Edit" title="VRX Photo Edit" />
          </div>
          <CardTitle tag="h2" className="mt-4 mb-2">Login</CardTitle>
          <Form onSubmit={e => onSubmit(e, navigate, searchParams)} noValidate>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input type="email" name="email" id="email" placeholder="you@email.com" required />
            </FormGroup>
            <FormGroup>
              <Label for="password">Password
                <small><Link to={forgotPassword} className="gray-link ms-2">Forgot it?</Link></small>
              </Label>
              <Input type="password" name="password" id="password" placeholder="top secret password" pattern=".{6,}" required />
            </FormGroup>
            <Button type="submit" className="btn-vrx">submit</Button>
          </Form>
          <div className="mt-3">
            <small>No account yet? <Link to={register} className="gray-link">Sign Up!</Link></small>
          </div>
          {/* <OAuth /> */}
        </CardBody>
      </Card>
    </Col>
  </Row>
)};

Login.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default Login;