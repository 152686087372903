import React, { useEffect } from 'react';
import { Button, Col, Container, Input, InputGroup, Row, Table} from 'reactstrap';
import PropTypes from 'prop-types';
import { KW_CUSTOMER, REDFIN_CUSTOMER } from '../../constants/roles';
import { useNavigate } from 'react-router-dom';

const CartComponent = ({ cartItems, createOrder, fetchCart, fetchProducts, products, removeCartItem, role, subtotal, totalPhotos, updateCartItem }) => {
  const navigate = useNavigate();
  useEffect(() => {
    fetchCart();
    fetchProducts();
  }, [fetchCart, fetchProducts]);

  return (
    <Container className="cart">
      <Row>
        <Col>
          <h1>Cart</h1>
        </Col>
      </Row>
      <Table responsive>
        <thead>
          <tr className="align-middle text-center">
            <th></th>
            <th></th>
            <th className="text-start">Product</th>
            {role !== KW_CUSTOMER && role !== REDFIN_CUSTOMER ?
              <th>Price</th> : 
            null}
            <th>Quantity</th>
            {role !== KW_CUSTOMER && role !== REDFIN_CUSTOMER ? 
              <th>Subtotal</th> :
            null}
          </tr>
        </thead>
        <tbody>
        {!products.length ? <tr className="text-center">
            <td colSpan="6">loading</td>
          </tr> : 
        !cartItems.length ? 
          <tr className="text-center">
            <td colSpan="6">No items in your cart</td>
          </tr> : cartItems.map((item, index) => {
            const product = products.reduce((accProd, curProd) => (
              curProd.id === item.productId ? curProd : accProd
            ), { price: 0 })
            return <tr key={`cart-item-${item.productId}`} className="align-middle text-center">
              <td>
                <Button outline size="sm"
                  onClick={() => removeCartItem(item.productId)}>
                    Remove
                </Button>
              </td>
              <td className="text-end">
                {product.images.length ? 
                  <img src={product.images[0].src} className="product-image d-none d-md-inline" alt="" /> : 
                ''}
              </td>
              <td className="text-start">{product.name}</td>
              {role !== KW_CUSTOMER && role !== REDFIN_CUSTOMER ?
                <td>${parseFloat(product.price).toFixed(2)}</td> :
              null}
              <td>
                <InputGroup className="m-auto mw-9rem">
                  <Button className="btn-vrx d-none d-md-block" size="sm"
                    onClick={_ => updateCartItem(
                      item.productId,
                      item.quantity > 1 ? item.quantity - 1 : 1)}> - 
                  </Button>
                  <Input type="number" className="no-arrows text-center" name="quantity" id="quantity" inputMode="numeric" placeholder="1" min="1" max="100" value={item.quantity} 
                  onChange={e => updateCartItem(
                    item.productId,
                    Math.abs(parseInt(e.target.value))
                  )}/>
                  <Button className="btn-vrx d-none d-md-block" size="sm"
                    onClick={_ => updateCartItem(
                      item.productId,
                      item.quantity < 100 ? item.quantity + 1 : 100)}> + 
                  </Button>
                </InputGroup>
              </td>
              {role !== KW_CUSTOMER && role !== REDFIN_CUSTOMER ?
                <td>${(parseFloat(product.price) * item.quantity).toFixed(2)}</td> : 
              null}
            </tr>
          })}
        </tbody>
      </Table>
      {cartItems.length ? <Row>
        <Col lg={{ offset: 9, size: 3 }} md={{ offset: 8, size: 4 }}>
          <Table responsive>
            <tbody>
              <tr>
                <td className="text-start">Total Photos:</td>
                <td className="text-end">{totalPhotos}</td>
              </tr>
              {role !== KW_CUSTOMER && role !== REDFIN_CUSTOMER ? <tr>
                <td className="text-start">Subtotal:</td>
                <td className="text-end">${subtotal.toFixed(2)}</td>
              </tr> : null}
              <tr>
                <td colSpan="2" className="text-end">
                  <Button className="btn-vrx" onClick={() => createOrder(navigate)}>Create Order</Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>: null }
    </Container>
  );
};

CartComponent.propTypes = {
  cartItems: PropTypes.array.isRequired,
  createOrder: PropTypes.func.isRequired,
  fetchProducts: PropTypes.func.isRequired,
  fetchCart: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  removeCartItem: PropTypes.func.isRequired,
  role: PropTypes.string,
  subtotal: PropTypes.number,
  totalPhotos: PropTypes.number.isRequired,
  updateCartItem: PropTypes.func.isRequired
};

export default CartComponent;

/*

Products.propTypes = {
  fetchProducts: PropTypes.func.isRequired,
  onOrderFormSubmit: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default Products;
*/