import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

const CustomerOrderComponent = ({ getOrderInfo }) => {
  
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    getOrderInfo(navigate, params);
  }, [getOrderInfo, navigate, params])
  
  
  return (
    <div> </div>
  );
};

CustomerOrderComponent.propTypes = {
  getOrderInfo: PropTypes.func.isRequired
};

export default CustomerOrderComponent;