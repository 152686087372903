import React from "react";
import { IMaskInput } from 'react-imask';
import PropTypes from 'prop-types';
import { Button, Col, FormGroup, Input, Label, PopoverBody, PopoverHeader, Row, UncontrolledPopover } from 'reactstrap';

const CreditCardComponent = ({ showSaveCheckBox }) => {
  return <div>
    <FormGroup>
      <Label for="cardNumber" className="required">Card Number</Label>
      <IMaskInput mask="0000 0000 0000 0000 000" unmask={true} name="cardNumber" placeholder="xxxx xxxx xxxx xxxx" autoComplete="cc-number" className="form-control" required />
    </FormGroup>
    <Row>
      <Col>
        <FormGroup>
          <Label for="expiration" className="required">Exp Date</Label>
          <IMaskInput name="expiration" id="expiration" unmask={true} className="form-control" placeholder="mm / yy" mask="00 / 00" required />
        </FormGroup>
      </Col>
      <Col>
        <FormGroup>
          <Label for="cvc" className="required">CVC / CVV</Label>
          <Button color="clear" size="sm" className="rounded-circle mt-n2 ms-n1" id="cvcInfoBtn" type="button" tabIndex="-1">&#9432;</Button>
          <UncontrolledPopover trigger="focus" placement="right" target="cvcInfoBtn">
            <PopoverHeader>3-4 digit code</PopoverHeader>
            <PopoverBody>located on the back of your card</PopoverBody>
          </UncontrolledPopover>
          <IMaskInput name="cvc" id="cvc" unmask={true} className="form-control" placeholder="CVC" mask="0000" required />
        </FormGroup>
      </Col>
    </Row>
    {showSaveCheckBox ? <Row>
      <Col className="pl-3">
        <Label check className="pl-3">
          <Input type="checkbox" id="saveCard" name="saveCard" />{' '}
          Save Card
        </Label>
      </Col>
    </Row> : ''}
  </div>
};

CreditCardComponent.protoTypes = {
  showSaveCheckBox: PropTypes.bool
};

export default CreditCardComponent;
