import { REQUEST_PRODUCTS, SET_PRODUCT, SET_PRODUCTS, SET_USER } from '../actions/types';

const initialState = {
  fetched: false,
  fetching: false,
  items: [],
  product: {}
};

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_PRODUCTS:
      return Object.assign({}, state, { fetching: true });
    case SET_PRODUCT:
      return Object.assign({}, state, {
        product: action.product
      });
    case SET_PRODUCTS:
      return Object.assign({}, state, {
        fetched: true,
        fetching: false,
        items: action.products
      });
    case SET_USER:
      return action.user ? state : Object.assign({}, initialState);
    default:
      return state;
  }
};

export default productsReducer;