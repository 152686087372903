import React from "react";
import { IMaskInput } from 'react-imask';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';

const ContactInfoComponent = () => {
  return <div>
    <Row>
      <Col xs={6}>
        <FormGroup>
          <Label for="firstName" className="required">First Name</Label>
          <Input name="firstName" id="firstName" className="form-control" placeholder="First" required />
        </FormGroup>
      </Col>
      <Col xs={6}>
        <FormGroup>
          <Label for="lastName" className="required">Last Name</Label>
          <Input name="lastName" id="lastName" className="form-control" placeholder="Last" required />
        </FormGroup>
      </Col>
    </Row>
    <Row>
      <Col xs={6}>
        <FormGroup>
          <Label for="email">Email</Label>
          <Input type="email" name="email" id="email" className="form-control" placeholder="you@email.com" />
        </FormGroup>
      </Col>
      <Col xs={6}>
        <FormGroup>
          <Label for="phone">Phone</Label>
          <IMaskInput type="tel" name="phone" id="phone" unmask={true} className="form-control" placeholder="(xxx)-xxx-xxxx" mask="(000)-000-0000" />
        </FormGroup>
      </Col>
    </Row>
  </div>
};

export default ContactInfoComponent;
