import { auth, functionsURL } from '../constants/firebase';
import { blockUi, showAlert } from './';
import { REQUEST_CARDS, RESET_CARDS, SET_CARDS } from './types';

export const deleteCard = id => async (dispatch, getState) => {
  const state = getState();
  dispatch(blockUi(true));
  try {
    const token = await auth.currentUser.getIdToken();
    const options = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };
    const res = await fetch(`${functionsURL}/vrx/cards/${id}`, options);
    if(res.status !== 200)
      throw new Error('Could not delete card. Please try again later.');
    const cards = state.cards.items.filter(card => card.id !== id);
    dispatch(setCards(cards));
    dispatch(showAlert('success', 'Your card has been deleted'));
  } catch (e) {
    dispatch(showAlert('danger', e.message));
  }
  dispatch(blockUi(false));

};

export const fetchCardsIfNeeded = () => async (dispatch, getState) => {
  const state = getState();
  if(!auth.currentUser) return;
  if(state.cards.fetching) return;
  if(state.cards.fetched) return;
  dispatch(blockUi(true));
  dispatch({ type: REQUEST_CARDS });
  try {
    const token = await auth.currentUser.getIdToken();
    const options = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };
    const res = await fetch(`${functionsURL}/vrx/cards`, options);
    const cards = await res.json();
    dispatch(setCards(cards));
  } catch (e) {
    dispatch(showAlert('danger', e.message));
  }
  dispatch(blockUi(false));
};

export const resetCards = () => dispatch => {
  dispatch({ type: RESET_CARDS });
}

export const setCards = cards => dispatch => {
  dispatch({
    type: SET_CARDS,
    cards
  });
};