import React from 'react';
import { Col, Container, Nav, NavItem, NavLink, Row } from 'reactstrap';

const Footer = () => (
  <footer id="footer" className="footer mt-auto py-3">
    <Container>
      <Row>
        <Col lg="3" md="6" className="mb-4 text-center text-lg-left">
          <a href="https://vrxmedia.com/" target="_blank" rel="noreferrer" className="logo">
            <img src={`${process.env.PUBLIC_URL}/img/vrx-media-group-white_1x.png`} srcSet={`${process.env.PUBLIC_URL}/img/vrx-media-group-white_1x.png 1x, ${process.env.PUBLIC_URL}/img/vrx-media-group-white_2x.png 2x`} className="align-middle w-75" alt="VRX Media Group" title="VRX Media Group" />
          </a>
        </Col>
        <Col lg="4" md="6" className="footer-text border-left mb-4 text-center text-lg-left">
          <h5 className="mb-0">Information</h5>
          <p>VRX Staging is part of the <a href="https://vrxmedia.com/" target="_blank" rel="noreferrer">VRX Media Group</a></p>
          <p>Call Us: <a href="tel:18663141120">1-866-314-1120</a></p>
          <p>Hours: Mon-Fri (9am-5pm CST)</p>
        </Col>
        <Col lg="3" md="" className="border-left mb-4 text-center text-lg-left">
          <h5 className="mb-0">Links</h5>
          <Nav vertical className="footer-nav">
            <NavItem>
              <NavLink href="https://vrxmedia.com/contact-us/" target="_blank">Contact</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="https://vrxstaging.com/" target="_blank">VRX Staging</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="https://vrxmedia.com/" target="_blank">VRX Media Group</NavLink>
            </NavItem>
          </Nav>
        </Col>
        <Col lg="2" md="6" className="border-left mb-4 text-center text-lg-left">
          <h5 className="mb-0">Social Media</h5>
          <Nav className="footer-nav d-inline-flex">
            <NavLink href="https://www.facebook.com/vrxstaging/" target="_blank" className="soc-med">
              <img src={`${process.env.PUBLIC_URL}/img/soc-med/facebook-orange.svg`} alt="Facebook" title="Facebook"></img>
            </NavLink>
            <NavLink href="https://www.instagram.com/vrxvirtualstaging/" target="_blank" className="soc-med">
              <img src={`${process.env.PUBLIC_URL}/img/soc-med/instagram-orange.svg`} alt="Instagram" title="Instagram"></img>
            </NavLink>
            <NavLink href="https://vimeo.com/vrxmediagroup" target="_blank" className="soc-med">
              <img src={`${process.env.PUBLIC_URL}/img/soc-med/vimeo-orange.svg`} alt="Vimeo" title="Vimeo"></img>
            </NavLink>
            <NavLink href="https://www.linkedin.com/company/tenx-media-group-3dtourmarketplace.com-" target="_blank" className="soc-med">
              <img src={`${process.env.PUBLIC_URL}/img/soc-med/linkedin-orange.svg`} alt="LinkedIn" title="LinkedIn"></img>
            </NavLink>
          </Nav>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="text-center copyright mb-0">&#169; Copyright 2016 - {new Date().getFullYear().toString()} All Rights Reserved</p>
        </Col>
      </Row>
    </Container>
  </footer>
);
export default Footer;