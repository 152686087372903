import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { KW_CUSTOMER, REDFIN_CUSTOMER } from '../../constants/roles';
import { kwMarketPlace } from '../../constants/routes';
import { PASSWORD } from '../../constants/providerIds';
import { Button, Col, Form, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Row, Table } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import EditCards from '../EditCards';

const Profile = ({ onEmailSubmit, onNameSubmit, onPasswordSubmit, user }) => {
  const navigate = useNavigate();

  const [nameModalOpen, setNameModelOpen] = useState(false);
  const [passwordModalOpen, setPasswordModelOpen] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);

  const toggleNameModal = () => setNameModelOpen(!nameModalOpen);
  const togglePasswordModal = () => setPasswordModelOpen(!passwordModalOpen);
  const toggleEmailModal = () => setEmailModalOpen(!emailModalOpen);

  return (<Row>
    <Col>
      <h1>Profile</h1>
      <Table className="bg-white rounded">
        <tbody>
          <tr>
            <td className="align-bottom">Name</td>
            <td className="align-bottom">{user.displayName || 'not set'}</td>
            <td className="text-end align-bottom">
              <Button className="btn-vrx btn-sm" onClick={toggleNameModal}>Change</Button>
            </td>
          </tr>
          {user.providerData[0]?.providerId === PASSWORD ? <tr>
            <td className="align-bottom">Email</td>
            <td className="align-bottom">
              {user.email}
            </td>
            <td className="text-end align-bottom">
              <Button className="btn-vrx btn-sm" onClick={toggleEmailModal}>Change</Button>
            </td>
          </tr> : <tr />}
          {user.providerData[0]?.providerId === PASSWORD ? <tr>
            <td className="align-bottom">Password</td>
            <td className="align-bottom">********************</td>
            <td className="text-end align-bottom">
              <Button className="btn-vrx btn-sm" onClick={togglePasswordModal}>Change</Button>
            </td>
          </tr> : <tr />}
        </tbody>
      </Table>

      {user.claims?.role === KW_CUSTOMER ?
        <a href={kwMarketPlace} className="btn btn-vrx">KW Marketplace &#8599;</a>
        : user.claims?.role !== REDFIN_CUSTOMER ? <EditCards /> : ''}

      <Modal isOpen={nameModalOpen} toggle={toggleNameModal}>
        <Form onSubmit={e => onNameSubmit(e, toggleNameModal)} noValidate>
          <ModalHeader toggle={toggleNameModal}>Name</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="displayName">Change Name</Label>
              <Input type="text" name="displayName" id="displayName" placeholder="First Last" required />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleNameModal}>Cancel</Button>
            <Button className="btn-vrx">Save</Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={emailModalOpen} toggle={toggleEmailModal}>
        <Form onSubmit={e => onEmailSubmit(e, toggleEmailModal, navigate)} noValidate>
          <ModalHeader toggle={toggleEmailModal}>Email</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="email1">Change Email</Label>
              <Input type="email" name="email1" id="email1" placeholder="you@email.com" required />
            </FormGroup>
            <FormGroup>
              <Label for="email2">Confirm email</Label>
              <Input type="email" name="email2" id="email2" placeholder="you@email.com" required />
            </FormGroup>
            <FormGroup>
              <Label for="password">Password</Label>
              <Input type="password" name="password" id="password" placeholder="password" pattern=".{6,}" required />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleEmailModal}>Cancel</Button>
            <Button className="btn-vrx">Save</Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={passwordModalOpen} toggle={togglePasswordModal}>
        <Form onSubmit={e => onPasswordSubmit(e, togglePasswordModal)} noValidate>
          <ModalHeader toggle={togglePasswordModal}>Password</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="currentPassword">Current password</Label>
              <Input type="password" name="currentPassword" id="currentPassword" placeholder="current password" pattern=".{6,}" required />
            </FormGroup>
            <FormGroup>
              <Label for="newPassword">New password</Label>
              <Input type="password" name="newPassword" id="newPassword" placeholder="new password" pattern=".{6,}" required />
            </FormGroup>
            <FormGroup>
              <Label for="confirmPassword">Confirm new password</Label>
              <Input type="password" name="confirmPassword" id="confirmPassword" placeholder="new password again" pattern=".{6,}" required />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={togglePasswordModal}>Cancel</Button>
            <Button className="btn-vrx">Save</Button>
          </ModalFooter>
        </Form>
      </Modal>

    </Col>
  </Row>
  )
};

Profile.propTypes = {
  onEmailSubmit: PropTypes.func.isRequired,
  onNameSubmit: PropTypes.func.isRequired,
  onPasswordSubmit: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

export default Profile;