import PropTypes from 'prop-types';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { products } from '../../constants/routes';

const Verify = ({ onProductsLinkClick, onResendEmailLinkClick }) => {
  const navigate = useNavigate();
  return <div className="verify">
    <h1>Please, verify your email</h1>
    <p>Please, check your email and click the link we sent you when you registered to verify your email.</p>
    <p>Can't find it? <a href="/resend-email" id="resend" onClick={onResendEmailLinkClick}>Click here</a> to get another one.</p>
    <p>After you've verified your account, <Link to={products} onClick={e => onProductsLinkClick(e, navigate)}>click here</Link>.</p>
  </div>
};

Verify.propTypes = {
  onProductsLinkClick: PropTypes.func.isRequired,
  onResendEmailLinkClick: PropTypes.func.isRequired
};

export default Verify;