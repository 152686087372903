import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RRNavLink } from 'react-router-dom';
import { Collapse, Container, Nav, Navbar, NavbarBrand, NavbarToggler, NavLink } from 'reactstrap';
import { KW_CUSTOMER } from '../constants/roles'
import { routes } from '../constants';

const Header = ({ accessLevel, fetchCart, itemsInCart, loggedIn, role, verified }) => {

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  // const navigate = useNavigate();

  useEffect(() => {
    fetchCart();
  }, [fetchCart, loggedIn]);

  return (
    <header className="header">
      <Container>
        <Navbar light expand="sm">
          <NavbarBrand 
          // no home page for now
          //   href={routes.home} onClick={e => {
          //   e.preventDefault();
          //   navigate(routes.home);
          // }}
          >
            <img src={`${process.env.PUBLIC_URL}/img/vrx_staging_logo_wide.png`}
              srcSet={`${process.env.PUBLIC_URL}/img/vrx_staging_logo_wide.png 1x, ${process.env.PUBLIC_URL}/img/vrx_staging_logo_wide@2x.png 2x`}
              className="logo" alt="logo" title="VRX Staging"/>
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar className="justify-content-end">
            <Nav navbar>
              {verified && accessLevel > 0 ? <NavLink tag={RRNavLink} to={routes.customerOrders} key={routes.customerOrders} >Customer Orders</NavLink> : ''}
              {verified && role !== KW_CUSTOMER ? <NavLink tag={RRNavLink} to={routes.products} key={routes.products} >Products</NavLink> : ''}
              {(verified) ? ([
                <NavLink tag={RRNavLink} to={routes.orders} key={routes.orders} >Orders</NavLink>,
                <NavLink tag={RRNavLink} to={routes.profile} key={routes.profile} >Profile</NavLink>
              ]) : ''}
              {verified && role !== KW_CUSTOMER ? <NavLink tag={RRNavLink} to={routes.cart} key={routes.cart} className={`position-relative cart-link${itemsInCart ? ' has-items' : ''}`}>Cart {itemsInCart ? 
                  <span className="position-absolute badge rounded-pill bg-secondary">
                  {itemsInCart}
                </span> : ''}</NavLink> : ''}
              {loggedIn ? (
                <NavLink tag={RRNavLink} to={routes.logout}>Logout</NavLink>
              ) : (
                <NavLink tag={RRNavLink} to={routes.login}>Login</NavLink>
              )}
            </Nav>
          </Collapse>
        </Navbar>
      </Container>
    </header>
  )
};

Header.propTypes = {
  accessLevel: PropTypes.number.isRequired,
  fetchCart: PropTypes.func.isRequired,
  itemsInCart: PropTypes.number.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  role: PropTypes.string,
  verified: PropTypes.bool.isRequired
};

export default Header;