import AlertComponent from './AlertComponent';
import { connect } from 'react-redux';
import { fadeAlert } from '../actions';

const mapStateToProps = state => ({
  color: state.alert.color,
  text: state.alert.text,
  className: state.alert.className || ''
})

const mapDispatchToProps = dispatch => ({
  close: () => dispatch(fadeAlert())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertComponent)