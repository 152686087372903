import { connect } from 'react-redux';
import EditCardsComponent from './EditCardsComponent';
import { deleteCard, fetchCardsIfNeeded } from '../actions';

const mapStateToProps = state => ({
  cardsArray: state.cards.items
});

const mapDispatchToProps = dispatch => ({
  deleteCard: id => dispatch(deleteCard(id)),
  fetchCards: () => dispatch(fetchCardsIfNeeded())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCardsComponent);