import React, { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { auth } from '../constants/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { connect } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { routes } from '../constants';
import Footer from './FooterComponent';
import Header from './Header';
import Alert from './Alert';
import Blocker from './Blocker';
import { setUser, showAlert } from '../actions';

const App = ({ alert, blockUiMessage, uiIsBlocked, setUser, user }) => {

  const location = useLocation();
  const navigate = useNavigate();
  
  const locationChange = useCallback(({ pathname, search }) => { 
    const verified = user.verified;
    const anon = user.isAnonymous;
    const isAdmin = user.claims?.accessLevel > 0;
    if(anon && pathname === routes.home) {
      navigate(routes.login);
    } else if (pathname === routes.home) {
      navigate(routes.profile);
    }
    if(!isAdmin && routes.adminAccessRequired(pathname)) {
      navigate(routes.login);
    }
    if (!verified && routes.verificationRequired(pathname)) {
      navigate(`${routes.verify}?redirect=${encodeURIComponent(pathname)}`);
    }
    if (anon && routes.loginRequired(pathname)) {
      navigate(`${routes.login}${search}`);
    }
  }, [navigate, user.claims?.accessLevel, user.isAnonymous, user.verified]);

  const [isReady, setIsReady] = useState(false);
  
  const authStateChangedCallback = useCallback(async user => {
    setUser(user);
    if (user) {
      const tokenResult = await user.getIdTokenResult(true);
      user.claims = tokenResult.claims;
      setUser(user);
    } else {
      setUser(user);
    }
    setIsReady(true);
  }, [setUser]);

  useEffect(() => {
    if(isReady)
      locationChange(location);
    else
      onAuthStateChanged(auth, authStateChangedCallback);
  }, [authStateChangedCallback, isReady, location, locationChange]);

  return (
    <div className="d-flex flex-column h-100">
      <Header />
      <Container className="mb-5">
        {alert ? <Row>
          <Col>
            <Alert />
          </Col>
        </Row> : ''}
        { isReady ? <Outlet /> : '' }
      </Container>
      <Footer />
      { !isReady || uiIsBlocked ? <Blocker message={blockUiMessage} /> : '' }
    </div>
  );

};

const mapStateToProps = state => {
  return {
    alert: state.alert,
    uiIsBlocked: state.blockUi.blocked,
    blockUiMessage: state.blockUi.message,
    user: state.user
  }
};

const mapDispatchToProps = dispatch => {
  return {
    setUser: user => {
      dispatch(setUser(user));
      if (user?.claims?.role === 'redfin-customer') {
        const shown = sessionStorage.getItem('redfin-alert');
        if(!shown)
        {
          dispatch(showAlert('info', 'Only use this Redfin Staging Portal if you are NOT ordering photography from VRX / LMS. This is only for staging orders where we did not do the photography.'));
          sessionStorage.setItem('redfin-alert', true);
        }
      }
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);