import { DISMISS_ALERT, FADE_ALERT, SHOW_ALERT } from "../actions/types";
const alertReducer = (state = null, action) => {

  if (action.type === DISMISS_ALERT) {
    return null;
  }

  if (action.type === FADE_ALERT) {
    return Object.assign({}, state, { className: 'fade' });
  }

  if (action.type === SHOW_ALERT) {
    document.querySelector('header').scrollIntoView();
    return action.payload;
  }

  return state;
  
};

export default alertReducer