import { connect } from 'react-redux';
import ForgotPasswordComponent from './ForgotPasswordComponent';
import { blockUi, sendPasswordResetEmail, showAlert } from '../../actions';
import { login } from '../../constants/routes';

const onSubmit = async (e, dispatch, navigate) => {

  e.preventDefault();
  e.stopPropagation();
  e.target.classList.add('was-validated');
  
  const email = e.target.email.value;
  const valid = e.target.checkValidity();

  if(!valid) {
    return;
  }

  dispatch(blockUi(true));

  try{
    await sendPasswordResetEmail(email);
    dispatch(showAlert('success', 'An email has been sent to you with a link to reset your password.'));
    navigate(login);
  } catch (err) {
    dispatch(showAlert('danger', err.message));
  }

  dispatch(blockUi(false));

}

const mapDispatchToProps = dispatch => ({
  onSubmit: (e, navigate) => onSubmit(e, dispatch, navigate)
});

export default connect(
  null,
  mapDispatchToProps
)(ForgotPasswordComponent);