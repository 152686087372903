import { auth, functions } from '../../constants/firebase';
import { connect } from 'react-redux';
import KWOrderComponent from './KWOrderComponent';
import { blockUi, showAlert } from '../../actions';
import { orders } from '../../constants/routes';
import { setOrder } from '../../actions';
import { signInWithCustomToken } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';

const getOrderInfo = async (dispatch, navigate, id) => {

  dispatch(blockUi(true));

  try {

    const kwOrderLogin = httpsCallable(functions, 'kwOrderLogin');
    const res = await kwOrderLogin({ id });
    const order = res.data.order;
    const token = res.data.token;
    
    await signInWithCustomToken(auth, token);

    dispatch(setOrder(order));
    
    navigate(`${orders}/${order.id}`);

  } catch (e) {

    console.error(e);
    dispatch(showAlert('danger', e.message));
    
  }

  dispatch(blockUi(false));
}

const mapDispatchToProps = (dispatch, props) => ({
  getOrderInfo: (navigate, params) => getOrderInfo(dispatch, navigate, params.id)
});

export default connect(
  null,
  mapDispatchToProps
)(KWOrderComponent);