import React from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, CardImg, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { login } from '../../constants/routes';

const ForgotPassword = ({ onSubmit }) => {
  const navigate = useNavigate();
  return <Row className="forgot-password">
    <Col xl={{ size: 4, offset: 4 }} lg={{ size: 6, offset: 3 }} md="12">
      <Card body>
        <CardImg top src={`${process.env.PUBLIC_URL}/img/vrx-media-group-logo_1x.png`} className="w-75 mt-4 mx-auto" alt="VRX Photo Edit" title="VRX Photo Edit" />
        <h2 className="mt-4 mb-2">Forgot Password</h2>
        <Form onSubmit={e => onSubmit(e, navigate)} noValidate>
          <FormGroup>
            <Label for="email">email</Label>
            <Input type="email" name="email" id="email" placeholder="you@email.com" required />
          </FormGroup>
          <Button type="submit" className="btn-vrx">submit</Button>
        </Form>
      </Card>
      <p className="text-center mt-3 pt-2">
        <Link to={login} className="gray-link">&#8678; Back to login</Link>
      </p>
    </Col>
  </Row>;
}

ForgotPassword.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default ForgotPassword;