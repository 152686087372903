import { REQUEST_CARDS, RESET_CARDS, SET_CARD, SET_CARDS, SET_USER } from '../actions';

const card = {
  id: '',
  object: 'card',
  address_city: '',
  address_country: '',
  address_line1: '',
  address_line1_check: '',
  address_line2: '',
  address_state: '',
  address_zip: '',
  address_zip_check: '',
  brand: '',
  country: '',
  customer: '',
  cvc_check: '',
  dynamic_last4: null,
  exp_month: null,
  exp_year: null,
  fingerprint: '',
  funding: '',
  last4: '',
  metadata: {},
  name: '',
  tokenization_method: null
};

const initialState = {
  fetched: false,
  fetching: false,
  items: [],
  card
};

const cardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_CARDS: 
      return Object.assign({}, state, { fetching: true });
    case RESET_CARDS: 
      return Object.assign({}, initialState);
    case SET_CARDS:
      return Object.assign({}, state, {
        fetched: true,
        fetching: false,
        items: action.cards
      });
    case SET_CARD:
      return Object.assign({}, state, { order: action.card });
    case SET_USER:
      return action.user ? state : Object.assign({}, initialState);
    default:
      return state;
  }
};

export default cardsReducer;