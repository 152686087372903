import { functions, getOrderRef, ordersCollection } from '../constants/firebase';
import { REQUEST_CUSTOMER_ORDERS, SET_CUSTOMER_ORDERS, SET_FILTERED_CUSTOMER_ORDERS } from './types';
import { blockUi, getDownloadURLFromPath, showAlert } from '.';
import { formatAddressToOneLine, getFormAsObject, getItemsFromSnapshot, sortArrayOfObjects } from '../utils';
import { COMPLETE } from '../constants/orderStatuses';
// import { orderTypeNames } from '../constants/orderTypes';
import { deleteField, where, getDocs, updateDoc, query } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { WORDPRESS_ORDER } from '../constants/orderTypes';

let defaultStartDate = new Date((new Date()).toISOString().substring(0, 10));
defaultStartDate.setMonth(defaultStartDate.getMonth() - 3);

export const fetchCustomerOrdersIfNeeded = () => async (dispatch, getState) => {

  /* data cleanup start */
  // deleteBadCustomerOrdersData()
  // migrageOldOrderData();
  /* data cleanup end */

  const state = getState();
  if(state.customerOrders.fetching) return;
  if(state.customerOrders.fetched) return;
  dispatch(blockUi(true));
  dispatch({ type: REQUEST_CUSTOMER_ORDERS });
  const customerOrders = await fetchCustomerOrders(defaultStartDate);
  dispatch(setCustomerOrders(customerOrders));
  dispatch(setFilteredCustomerOrders(customerOrders));
  dispatch(blockUi(false));

}
/*
const migrageOldOrderData = async() => {
  let customerOrdersQuery = query(ordersCollection);
  const snapshot = await getDocs(customerOrdersQuery);
  let customerOrders = await getItemsFromSnapshot(snapshot);
  for(let i = 0; i < customerOrders.length; i++) {
    const order = customerOrders[i];
    if(!order.products) {
      console.log('need to update order products:', order.id);
      if(!order.type)
        continue;
      const products = {
        [order.type]: {
          name: orderTypeNames[order.type],
          price: order.price || 0,
          quantity: order.quantity
        }
      };
      const files = order.files.map(file => {
        file.productId = order.type;
        return file;
      })
      console.log('files:', files);
      console.log('products:', products);
      const orderRef = getOrderRef(order.id);
      try {
        await updateDoc(orderRef, { products, files });
      } catch (e) {        
        console.error('error:', e, 'could not update order:', order, 'products:', products);
        return;
      }
    } else {
      console.log('order already updated:', order);
    }
  }
};

const deleteBadCustomerOrdersData = async() => {
  let customerOrdersQuery = query(ordersCollection);
  const snapshot = await getDocs(customerOrdersQuery);
  let customerOrders = await getItemsFromSnapshot(snapshot);
  console.log('customerOrders:', customerOrders.length, customerOrders);
  customerOrders = customerOrders
    .filter(order => order.type !== 'WORDPRESS_ORDER' && order.type !== 'VRX_PHOTOS_ORDER')
    .forEach(async order => {
      console.log('order products:', order.products);
      const orderRef = getOrderRef(order.id);
      await updateDoc(orderRef, { products: deleteField() });
    });
}
*/

const fetchCustomerOrders = async (startDate, endDate = false) => {
  const customerOrdersQuery = endDate ? query(ordersCollection, 
    where('created', '>=', startDate.toISOString()),
    where('created', '<=', endDate.toISOString())) : query(ordersCollection, where('created', '>', startDate.toISOString()));
  const snapshot = await getDocs(customerOrdersQuery);
  let customerOrders = await getItemsFromSnapshot(snapshot);

  for(let i = 0; i < customerOrders.length; i++) {
    const order = customerOrders[i];
  
    // update wp orders that don't have a downloadURL
    let files = order.files || [];
    let needsFileUpdate = false;
    for (let f = 0; f < files.length; f++) {
      const file = files[f];
      if(file.storagePath && !file.downloadURL) {
        needsFileUpdate = true;
        file.downloadURL = await getDownloadURLFromPath(file.storagePath);
      }
    }

    const orderRef = getOrderRef(order.id);
    if(needsFileUpdate) {
      await updateDoc(orderRef, { files: deleteField() });
      await updateDoc(orderRef, { files });
    }

    // update old orders that don't have a products object
    // if(!order.products) {
    //   console.log('need to update order products:', order.id);
    //   const products = {
    //     [order.type]: {
    //       name: orderTypeNames[order.type],
    //       price: order.price || 0,
    //       quantity: order.quantity
    //     }
    //   };
    //   order.files = order.files.map(file => {
    //     file.productId = order.type
    //     return file
    //   })
    //   console.log('products:', products);
    //   const orderRef = getOrderRef(order.id);
    //   order.products = products;
    //   try {
    //     await updateDoc(orderRef, { products });
    //   } catch (e) {
        
    //     console.error('error:', e, 'could not update order:', order, 'products:', products);
    //   }
    // }
  }
  return sortArrayOfObjects(customerOrders, 'created')
    .reverse()
    .map(order => {
      order.addressString = formatAddressToOneLine(order.address);
      return order;
    });
};

export const filterCustomerOrders = e => async (dispatch, getState) => {
  let customerOrders = getState().customerOrders.items;
  const filters = getFormAsObject(e.currentTarget);
  const startDate = new Date(filters.startDate);
  const endDate = new Date(filters.endDate);
  startDate.setUTCHours(0,0,0,0);
  endDate.setUTCHours(23,59,59,999);

  if(startDate < defaultStartDate) {
    dispatch(blockUi(true));
    const moreCustomerOrders = await fetchCustomerOrders(startDate, defaultStartDate);
    customerOrders = [...new Set([...customerOrders, ...moreCustomerOrders])]
    dispatch(setCustomerOrders(customerOrders));
    dispatch(blockUi(false));
    defaultStartDate = startDate;
  }

  const filteredCustomerOrders = customerOrders
    .filter(order => {
      const created = new Date(order.created);
      return created >= startDate &&
        created <= endDate &&
        // (filters.type ? order.type === filters.type : true) &&
        (filters.origin && filters.origin === WORDPRESS_ORDER ? 
          order.type === WORDPRESS_ORDER :
          filters.origin && filters.origin !== WORDPRESS_ORDER ? order.type !== WORDPRESS_ORDER :
          true) &&
        (filters.status ? order.status === filters.status : true) && 
        (filters.customerEmail ? order.customerEmail.toLowerCase().indexOf(filters.customerEmail.toLowerCase()) >= 0 : true) &&
        (filters.address ? formatAddressToOneLine(order.address).toLowerCase().indexOf(filters.address.toLowerCase()) >= 0 : true);
    });
  dispatch(setFilteredCustomerOrders(filteredCustomerOrders));
}

export const markCustomerOrderComplete = order => async dispatch => {
  dispatch(blockUi(true)); 

  const completeOrder = httpsCallable(functions, 'completeOrder');
  const res = await completeOrder({ id: order.id });

  if (res.data.error) {
    dispatch(showAlert('danger', res.data.error));
  } else {
    dispatch(updateCustomerOrderStatus(order, COMPLETE));
  }

  dispatch(blockUi(false));
}

export const sortCustomerOrders = (prop, asc) => (dispatch, getState) => {
  let filteredCustomerOrders = sortArrayOfObjects(getState().customerOrders.filteredItems, prop);
  if(asc)
    filteredCustomerOrders.reverse();    
  dispatch(setFilteredCustomerOrders(filteredCustomerOrders));
}

export const setCustomerOrders = customerOrders => dispatch => {
  dispatch({
    type: SET_CUSTOMER_ORDERS,
    customerOrders
  });
};

export const setFilteredCustomerOrders = filteredCustomerOrders => dispatch => {
  dispatch({
    type: SET_FILTERED_CUSTOMER_ORDERS,
    filteredCustomerOrders
  });
};

export const setCustomerOrderStatus = (order, orderStatus) => async dispatch => {
  if(orderStatus === COMPLETE)
    return dispatch(markCustomerOrderComplete(order));
  dispatch(blockUi(true)); 
  try{
    const orderRef = getOrderRef(order.id);
    await updateDoc(orderRef, {
      status: orderStatus
    });
    dispatch(updateCustomerOrderStatus(order, orderStatus));
  } catch(e){
    dispatch(showAlert('danger', e.message));
    dispatch(blockUi(false));
    return;
  }
  order.status = orderStatus;
  dispatch(blockUi(false));
};

const updateCustomerOrderStatus = (order, orderStatus) => (dispatch, getState) => {
  const state = getState();
  const customerOrders = state.customerOrders.items.map(_order => {
    if(_order.id === order.id)
      _order.status = orderStatus;
    return _order;
  });
  dispatch(setCustomerOrders(customerOrders));
  dispatch(setFilteredCustomerOrders(state.customerOrders.filteredItems.map(_order => {
    if(_order.id === order.id)
      _order.status = orderStatus;
    return _order;
  })));
  dispatch(showAlert('success', `order for ${order.customerName || order.customerEmail || 'not set'} marked ${orderStatus}`));
};