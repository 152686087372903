export const formatAddressToOneLine = address => {
  if (address.street1 && 
    address.city && 
    address.state &&
    address.zip) {
    return `${address.street1}, ${address.street2 ? address.street2 + ', ' : ''} ${address.city}, ${address.state} ${address.zip}`;
  } else {
    return 'not set';
  }

};