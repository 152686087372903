import { connect } from 'react-redux';
import ProductsComponent from './ProductsComponent';
import { addToCart, fetchProductsIfNeeded } from '../../actions';

const onOrderFormSubmit = async (e, product, toggleOrderModal, dispatch, navigate, setLastProductAdded) => {

  e.preventDefault();
  e.stopPropagation();
  e.target.classList.add('was-validated');

  const form = e.target;

  if (!form.checkValidity()) {
    return;
  }
  
  dispatch(addToCart(product.id, parseInt(form.quantity.value)));
  toggleOrderModal();
  setLastProductAdded(product);
};

const mapStateToProps = state => ({
  products: state.products.items,
  role: state.user?.claims?.role
});

const mapDispatchToProps = (dispatch, props) => ({
  onOrderFormSubmit: (e, product, toggleOrderModal, navigate, setLastProductAdded) => onOrderFormSubmit(e, product, toggleOrderModal, dispatch, navigate, setLastProductAdded),
  fetchProducts: () => dispatch(fetchProductsIfNeeded())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductsComponent);