import CartComponent from './CartComponent';
import { connect } from 'react-redux';
import { createOrder, fetchProductsIfNeeded, fetchCartIfNeeded, removeCartItem, updateCartItem } from '../../actions';

const mapStateToProps = state => ({
  products: state.products.items,
  cartItems: state.cart.items,
  role: state.user.claims?.role || '',
  subtotal: state.cart.items.reduce((total, item) => {
    const product = state.products.items.filter(p => p.id === item.productId)[0];
    return total + parseFloat(product?.price || 0) * item.quantity;
  }, 0),
  totalPhotos: state.cart.items.reduce((total, item) => total + item.quantity, 0)
});

const mapDispatchToProps = (dispatch, _props) => ({
  createOrder: (navigate) => dispatch(createOrder(navigate)),
  fetchCart: () => dispatch(fetchCartIfNeeded()),
  fetchProducts: () => dispatch(fetchProductsIfNeeded()),
  removeCartItem: productId => dispatch(removeCartItem(productId)),
  updateCartItem: (productId, quantity) => dispatch(updateCartItem(productId, quantity))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CartComponent);