import { functions } from '../../constants/firebase';
import { connect } from 'react-redux';
import ResendOrderComponent from './ResendOrderComponent';
import { blockUi, showAlert } from '../../actions';
import { httpsCallable } from 'firebase/functions';

const getOrderInfo = async (dispatch, id) => {

  dispatch(blockUi(true));

  try {

    const resendOrder = httpsCallable(functions, 'resendOrder');
    const res = await resendOrder({ id });

    if (res.data.error) {
      dispatch(showAlert('danger', res.data.error));
    } else {
      dispatch(showAlert('success', 'The order has been resent. You can close this page.'));
    }

  } catch (e) {

    console.error(e);
    dispatch(showAlert('danger', e.message));
    
  }

  dispatch(blockUi(false));

}

const mapDispatchToProps = (dispatch, props) => ({
  resendOrder: () => getOrderInfo(dispatch, props.match.params.id)
});

export default connect(
  null,
  mapDispatchToProps
)(ResendOrderComponent);