import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

const KWOrderComponent = ({ getOrderInfo }) => {
  
  const navigate = useNavigate();
  const params = useParams();
  
  useEffect(() => {
    getOrderInfo(navigate, params);
  })
  
  return (
    <div> </div>
  );
};

KWOrderComponent.propTypes = {
  getOrderInfo: PropTypes.func.isRequired
};

export default KWOrderComponent;