import { DISMISS_ALERT, FADE_ALERT, SHOW_ALERT } from "./types";

let theTimeout = null;

const dismissAlert = () => (
  dispatch => {
    clearTimeout(theTimeout);
    dispatch({ type: DISMISS_ALERT });
  }
);

export const fadeAlert = () => ( 
  dispatch => {
    clearTimeout(theTimeout);
    theTimeout = setTimeout(() => dispatch(dismissAlert()), 300);
    dispatch({
      type: FADE_ALERT
    });
  }
);

export const showAlert = (color, text) => (
  dispatch => {
    clearTimeout(theTimeout);
    theTimeout = setTimeout(() => dispatch(fadeAlert()), 15000);
    dispatch({
      type: SHOW_ALERT, 
      payload: { color: color, text: text }
    })
  }
);
