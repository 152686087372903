import jszip from 'jszip';
import resize from 'resize-image';
import { blockUi, setOrder, showAlert } from './';
import { auth, getOrderRef, storage } from '../constants/firebase';
import { dataURLToBlob, getFileList } from '../utils';
import { updateDoc } from 'firebase/firestore';
import { deleteObject, getDownloadURL, listAll, ref, uploadBytes, uploadBytesResumable } from 'firebase/storage';

export const deleteFiles = e => async (dispatch, getState) => {

  e.preventDefault();
  e.stopPropagation();

  dispatch(blockUi(true));
  const id = getState().orders.order.id;
  
  try {
    const fileListRef = ref(storage, `/orders/${auth.currentUser.uid}/${id}`);
    const fileList = await listAll(fileListRef);
    for (const fileRef of fileList.items) {
      await deleteObject(fileRef);
    }
    await dispatch(saveOrderFiles([], ''));
  } catch (e) {
    dispatch(showAlert('danger', e.message));
  }

  dispatch(blockUi(false));

};

export const deleteImage = (imgNum) => async (dispatch, getState) => {
  const order = getState().orders.order;
  try {
    order.files[imgNum].dataURL = '';
    dispatch(setOrder({...order}));
    await deleteObject(ref(storage, `/orders/${auth.currentUser.uid}/${order.id}/${order.files[imgNum].file.name}`));
    order.files.splice(imgNum, 1);
    dispatch(setOrder({...order}));
  } catch (e) {
    dispatch(showAlert('danger', e.message));
  }
};

export const deleteImagesForProduct = productId => async (dispatch, getState) => {
  const order = getState().orders.order;
  try {
    let filesToDlete = [];
    order.files = order.files.filter((file, index) => {
      const shouldKeep = file.productId !== productId;
      if(!shouldKeep)
        filesToDlete.push(order.files[index].name);
      return shouldKeep;
    });
    dispatch(setOrder({...order}));
    for(let i = 0; i < filesToDlete.length; i++ ) {
      await deleteObject(ref(storage, `/orders/${auth.currentUser.uid}/${order.id}/${filesToDlete[i]}`));
    }
  } catch (e) {
    dispatch(showAlert('danger', e.message));
  }
};

export const getDownloadURLFromPath = async path => {
  const fileRef = ref(storage, path);
  return await getDownloadURL(fileRef);
};

export const loadPreviewImage = imgNum => (dispatch, getState) => {
  
  const order = getState().orders.order;
  if(!order.files[imgNum]?.file) return;

  const image = new Image();
  const reader = new FileReader();

  image.onload = async e => {
    
    const img = e.target;
    const width = 400;
    const height = Math.floor(img.height / (img.width / width));
    const dataURL = resize.resize(img, width, height);
    
    try{
      const fileRef = ref(storage, `/orders/${auth.currentUser.uid}/${order.id}/${order.files[imgNum].file.name}`);
      await uploadBytes(fileRef, dataURLToBlob(dataURL));
      const downloadURL = await getDownloadURL(fileRef);
      
      order.files[imgNum].dataURL = dataURL;
      order.files[imgNum].downloadURL = downloadURL;
      dispatch(setOrder({...order}));

    } catch (e) {
      dispatch(showAlert('danger', e.message));
    }

  };

  reader.onloadend = e => image.src = e.target.result;
  reader.readAsDataURL(order.files[imgNum].file);

};

export const orderFilesSelected = (e, productId) => (dispatch, getState) => {

  const selectedFiles = getFileList(e);
  const order = getState().orders.order;

  if(Object.values(selectedFiles).length !== order.products[productId].quantity)
    return dispatch(showAlert('danger', `You selected ${selectedFiles.length}
    ${selectedFiles.length > 1 ? 'files' : 'file'}. 
    You need to select ${order.products[productId].quantity}
    ${order.products[productId].quantity > 1 ? 'files' : 'file'}
    for ${order.products[productId].name}.`));

  // if((order.files.length + selectedFiles.length) > order.quantity)
  //   return dispatch(showAlert('danger', `You selected too many files. The quantity for this order is: ${order.quantity}.`));

  let errMsg = '';
  const files = Object.values(selectedFiles).reduce(
    (files, file) => {
      if (files.find(existing => existing.name === file.name)) {
        errMsg = `You've already selected a file named: ${file.name}.`;
        // dispatch(showAlert('danger', `You've already selected a file named: ${file.name}.`));
      } else if (file.type.match('image.*')) {
        files = files.concat([{file, name: file.name, productId}]);
      } else {
        errMsg = 'Only image files can be uploaded.';
        // dispatch(showAlert('danger', 'Only image files can be uploaded.'));
      }
      return files;
    }, order.files.slice());
  
  e.target.value = '';

  if(errMsg)
    return dispatch(showAlert('danger', errMsg));

  order.files = files;
  
  // order.files = Object.values(selectedFiles).reduce(
  //   (files, file) => {
  //     if (files.find(existing => existing.name === file.name)) {
  //       dispatch(showAlert('danger', `You've already selected a file named: ${file.name}.`));
  //     } else if (file.type.match('image.*')) {
  //       files = files.concat([{file, name: file.name, productId}]);
  //     } else {
  //       dispatch(showAlert('danger', 'Only image files can be uploaded.'));
  //     }
  //     return files;
  //   }, order.files);

  return dispatch(setOrder(order));

};

export const saveOrderFiles = (files, zipURL) => async (dispatch, getState) => {
  const obj = { files, zipURL};
  const order = getState().orders.order;
  const orderRef = getOrderRef(order.id);
  await updateDoc(orderRef, obj);
  dispatch(setOrder(Object.assign({}, order, obj)));
}

export const uploadFiles = (e, setProgress) => async (dispatch, getState) => {
  e.preventDefault();
  e.stopPropagation();

  const order = getState().orders.order;

  if (!order.files.length) {
    return dispatch(showAlert('danger', 'You must select some photos to upload.'));
  }

  dispatch(blockUi(true));
  const zip = new jszip();
  let notes = '';
  Object.entries(order.products).forEach((entry, productIndex) => {
    const folder = zip.folder(entry[1].name);
    if(productIndex > 0)
      notes += '\n--------------------\n\n\n';
    notes += `${entry[1].name}\n\n`;
    order.files
      .forEach((obj, index) => {
        if(obj.productId !== entry[0])
          return;
        const note = e.target[`note_${index}`].value;
        obj.note = note;
        notes += `${obj.file.name}\n${note}\n\n`;
        folder.file(obj.file.name, obj.file);
    });
  });
  zip.file('notes.txt', notes); 

  let blob;
  try {
    blob = await zip.generateAsync({
      type: "blob",
      compression: "DEFLATE",
      compressionOptions: {
        level: 9
      },
      mimeType: 'application/zip'
    });
  } catch (e) {
    dispatch(blockUi(false));
    return dispatch(showAlert('danger', e.message));
  }

  const metadata = { contentType: 'application/zip' };
  const fileRef = ref(storage, `/orders/${auth.currentUser.uid}/${order.id}/${order.id}.zip`);
  const uploadTask = uploadBytesResumable(fileRef, blob, metadata);

  dispatch(blockUi(false));

  uploadTask.on('state_changed', 
    snapshot => {
      const progress = Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100).toString();
      return setProgress(progress);
    }, 
    e => dispatch(showAlert('danger', e.message)),
    async () => {
      dispatch(blockUi(true));
      const files = order.files.reduce((files, obj) => {
        files.push({
          downloadURL: obj.downloadURL,
          name: obj.file.name,
          note: obj.note,
          productId: obj.productId
        });
        return files;
      }, []);
      const zipURL = await getDownloadURL(uploadTask.snapshot.ref);
      
      await dispatch(saveOrderFiles(files, zipURL));
      setProgress('complete');
      dispatch(showAlert('success', 'Your images have been uploaded.'));
      dispatch(blockUi(false));
    }
  );
};