import React, { useState, useEffect, useRef } from "react";
import { Alert, Button, FormGroup, Input, Label, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { AddressComponent, ContactInfoComponent } from "./";
import CreditCardComponent from "./CrediCardComponent";

const PaymentForm = ({ cardsArray, cardsFetched, orderId, fetchCards, finalizeOrder, getPaymentIntent, isOpen, onSubmit, paymentIntent, processing, setProcessing, succeeded, togglePaymentModal }) => {
  
  const navigate = useNavigate();
  const paymentForm = useRef(null);
  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(true);
  // const [processing, setProcessing] = useState(false);
  const [usingSavedCard, setUsingSavedCard] = useState(false);
  const setErrorMsg = msg => {
    paymentForm.current?.classList?.remove('was-validated');
    return setError(msg);
  }

  useEffect(() => {
    if(error)
      console.log('error:', error);
    fetchCards();
    if (succeeded && !isOpen) {
      finalizeOrder(navigate);
    }
    getPaymentIntent(togglePaymentModal);
    if(paymentIntent) {
      setDisabled(false);
    }
    setUsingSavedCard(cardsArray.length && !succeeded && !processing && paymentIntent);
  }, [cardsArray, cardsFetched, error, paymentIntent, fetchCards, finalizeOrder, getPaymentIntent, isOpen, navigate, processing, succeeded, togglePaymentModal]);

  return (
    <form id="payment-form" ref={paymentForm} noValidate onSubmit={e => onSubmit(e, setErrorMsg, setProcessing)}>
      <Input type="hidden" defaultValue={orderId} name="orderId" />
      <ModalBody>
        {error ? <Alert color="danger">{error}</Alert> : ''}
        {succeeded ? <Alert color="success">Payment Succeeded!</Alert> : ''}
        {usingSavedCard ? <FormGroup>
          <Label for="savedCard">Saved Cards</Label>
          <Input type="select" id="savedCard" name="savedCard"
            onChange={e => setUsingSavedCard(e.target.value ? true : false)}>
            {cardsArray.map((card, index) => <option 
              value={card.id} 
              key={`card-${index}`}>
                {card.brand} card ending in: {card.last4}
              </option>)}
            <option value="">Other</option>
          </Input>
        </FormGroup> : ''}
        {cardsFetched && paymentIntent && !processing && !succeeded && !usingSavedCard ? <div>
          <h5>Credit Card</h5>
          <CreditCardComponent showSaveCheckBox={true} />
          <h5 className="mt-3">Billing Address</h5>
          <ContactInfoComponent />
          <AddressComponent />
        </div> : ''}
        {!cardsFetched || !paymentIntent || processing ? <div className="text-center">
          <Spinner color="secondary"/>
        </div> : ''}
      </ModalBody>
      {succeeded ? <ModalFooter>
        <Button className="btn-vrx" onClick={togglePaymentModal} id="paymentOkBtn">OK</Button>
      </ModalFooter> : <ModalFooter>
        <Button color="secondary" onClick={togglePaymentModal} disabled={disabled || processing}>Cancel</Button>
        <Button className="btn-vrx" disabled={disabled || processing}>Submit</Button>
      </ModalFooter>}
    </form>
  );
};

PaymentForm.protoTypes = {
  cardsArray: PropTypes.array.isRequired,
  cardsFetched: PropTypes.bool.isRequired,
  orderId: PropTypes.string.isRequired,
  paymentIntent: PropTypes.string.isRequired,
  fetchCards: PropTypes.func.isRequired,
  finalizeOrder: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
  setProcessing: PropTypes.func.isRequired,
  succeeded: PropTypes.bool.isRequired
};

export default PaymentForm;
