import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';

const EditCardsComponent = ({ cardsArray, deleteCard, fetchCards }) => {
  
  useEffect(() => {
    fetchCards();
  })
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [last4, setLast4] = useState('');
  const [cardId, setCardId] = useState('');

  const toggleDeleteOpen = () => setDeleteOpen(!deleteOpen);
  
  return (
    <div className="edit-cards border-top mt-4 pt-4 border-white">
      <h5>Saved Cards</h5>
      {cardsArray.length ? <Table className="bg-white rounded" responsive={true}>
        <thead>
          <tr>
            <th scope="col">Cardholder</th>
            <th scope="col">Brand</th>
            <th scope="col">Expiration</th>
            <th scope="col">Last&nbsp;4</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody className="border-top">
          {cardsArray.map((card, index) => {
            return <tr key={`card-${index}`}>
              <td className="align-bottom">{card.name}</td>
              <td className="align-bottom">{card.brand}</td>
              <td className="align-bottom">{card.exp_month}/{card.exp_year.toString().substr(2)}</td>
              <td className="align-bottom">{card.last4}</td>
              <td className="text-end align-bottom">
                <Button color="danger" size="sm" onClick={() => {
                  setLast4(card.last4);
                  setCardId(card.id);
                  toggleDeleteOpen();
                }}>Delete</Button>
              </td>
            </tr>
          })}
        </tbody>
      </Table> : <Table className="bg-white rounded">
        <thead>
          <tr>
            <th scope="col">no saved cards</th>
          </tr>
        </thead>
      </Table>}
      <Modal isOpen={deleteOpen} toggle={toggleDeleteOpen}>
        <ModalHeader toggle={toggleDeleteOpen}>Are you sure?</ModalHeader>
        <ModalBody>
          Are you sure you want to delete the card ending in <strong>{last4}</strong>?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => {
            deleteCard(cardId);
            toggleDeleteOpen();
          }}>Yes</Button>
          <Button color="secondary" onClick={toggleDeleteOpen}>No</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

EditCardsComponent.propTypes = {
  cardsArray: PropTypes.array.isRequired,
  deleteCard: PropTypes.func.isRequired,
  fetchCards: PropTypes.func.isRequired
};

export default EditCardsComponent;