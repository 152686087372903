import PaymentFormComponent from './PaymentFormComponent';
import { connect } from 'react-redux';
import { confirmPaymentIntent, fetchCardsIfNeeded, finalizeOrder, getPaymentIntent } from '../actions';
import { formIsValid } from '../utils';

const mapStateToProps = state => ({
  cardsArray: state.cards.items,
  cardsFetched: state.cards.fetched,
  orderId: state.orders.order.id,
  paymentIntent: state.orders.order.paymentIntent,
  succeeded: state.orders.order.paymentIntentStatus === 'succeeded'
});

const mapDispatchToProps = dispatch => ({
  getPaymentIntent: togglePaymentModal => dispatch(getPaymentIntent(togglePaymentModal)),
  fetchCards: () => dispatch(fetchCardsIfNeeded()),
  finalizeOrder: navigate => dispatch(finalizeOrder(navigate)),
  handleChange: (e, setDisabled, setError) => {
    setDisabled(e.empty);
    setError(null);
    if (e.error) {
      setError(e.error.message);
    }
  },
  onSubmit: async (e, setError, setProcessing) => {
    if (!formIsValid(e)) { return; }
    const form = e.target;
    setProcessing(true);

    const expArr = form.expiration?.value?.split('/');
    const body = form.savedCard ? {
      orderId: form.orderId.value,
      payment_method: form.savedCard.value
    } : {
      orderId: form.orderId.value,
      billing_details: {
        address: {
          city: form.city.value,
          line1: form.street1.value,
          line2: form.street2.value,
          postal_code: form.zip.value,
          state: form.state.value
        },
        email: form.email.value ? form.email.value : undefined,
        phone: form.phone.value ? form.phone.value : undefined,
        name: `${form.firstName.value} ${form.lastName.value}`
      },
      card: {
        number: form.cardNumber.value.replace(/ /g, ''),
        exp_month: expArr[0]?.trim(),
        exp_year: new Date().getFullYear().toString().substr(0, 2) + expArr[1]?.trim(),
        cvc: form.cvc.value,
      },
      saveCard: form.saveCard.checked
    }
    dispatch(confirmPaymentIntent(body, setError, setProcessing));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentFormComponent);