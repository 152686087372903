export const VIRTUAL_STAGING = 'VIRTUAL_STAGING';
export const VIRTUAL_TWILIGHT = 'VIRTUAL_TWILIGHT';
export const WALL_PAINT_COLOR_CHANGE = 'WALL_PAINT_COLOR_CHANGE';
export const SPECIAL_EDITING_REQUEST = 'SPECIAL_EDITING_REQUEST';
export const FURNITURE_REMOVAL_AND_VIRTUAL_STAGING = 'FURNITURE_REMOVAL_AND_VIRTUAL_STAGING';
export const BLUE_SKY_GREEN_GRASS = 'BLUE_SKY_GREEN_GRASS';
export const WORDPRESS_ORDER = 'WORDPRESS_ORDER';
export const VRX_PHOTOS_ORDER = 'VRX_PHOTOS_ORDER';

export const orderTypeNames = {
  VIRTUAL_STAGING: 'Virtual Staging',
  VIRTUAL_TWILIGHT: 'Virtual Twilight',
  WALL_PAINT_COLOR_CHANGE: 'Wall Paint Color Change',
  SPECIAL_EDITING_REQUEST: 'Special Editing Request',
  FURNITURE_REMOVAL_AND_VIRTUAL_STAGING: 'Furniture Removal & Virtual Staging',
  BLUE_SKY_GREEN_GRASS: 'Blue Sky Green Grass',
  WORDPRESS_ORDER: 'vrxstaging.com',
  VRX_PHOTOS_ORDER: 'Redfin'
}

export const skus = {
  sped01: SPECIAL_EDITING_REQUEST,
  frvs01: FURNITURE_REMOVAL_AND_VIRTUAL_STAGING,
  pcc01: WALL_PAINT_COLOR_CHANGE,
  bsg01: BLUE_SKY_GREEN_GRASS,
  vt02: VIRTUAL_TWILIGHT,
  vs01: VIRTUAL_STAGING
}