import { connect } from 'react-redux';
import HeaderComponent from './HeaderComponent';
import { fetchCartIfNeeded } from '../actions';

const mapStateToProps = (state, props) => ({
  accessLevel: state.user.claims?.accessLevel || 0,
  itemsInCart: state.cart.items.length,
  loggedIn: !state.user.isAnonymous,
  role: state.user.claims ? state.user.claims.role : '',
  verified: state.user.verified
});

const mapDispatchToProps = (dispatch, props) => ({
  fetchCart: () => dispatch(fetchCartIfNeeded())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderComponent);